import { Box, Grid, Typography } from '@mui/material';
import { Button, Card, Modal } from '@perry-weather/component-library';
import AirQualityGauge from './AqiGauge';
import { PollutantData, useGetAirQualityForLocationQuery } from '../../../Services/API';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AqiType } from '../../../Constants/AqiConstants';
import moment from 'moment';

type AirQualityDetailsModalProps = {
  locationId: string;
  open: boolean;
  handleClose: () => void;
  handleOpen?: () => void;
};

const Row = ({ label, value }: { label: string; value: string | number }) => (
  <Box justifyContent={'space-between'} flexDirection={'row'} display={'flex'}>
    <Typography>{label}:</Typography>
    <Typography align='right'>{value}</Typography>
  </Box>
);

interface PollutantDataProps {
  pollutant: PollutantData;
}

const PollutantDetails = ({ pollutant }: PollutantDataProps) => {
  const sourceName = pollutant?.sourceName === 'PerryWeather' ? 'Perry Weather' : pollutant?.sourceName;

  return (
    <Box>
      <Row label='NowCast' value={pollutant?.aqiData.nowCastIndex?.value ?? '-'} />
      <Row label='1-Hr AQI' value={pollutant?.aqiData.oneHourIndex?.value ?? '-'} />
      {pollutant.pollutantName === 'PM2.5' || pollutant.pollutantName === 'PM10' ? (
        <Row label='24-Hr AQI' value={pollutant?.aqiData.twentyFourHourIndex?.value ?? '-'} />
      ) : (
        <Row label='8-Hr AQI' value={pollutant?.aqiData.eightHourIndex?.value ?? '-'} />
      )}
      <Row label='Source' value={sourceName ?? '-'} />
      <Row label='Distance' value={`${pollutant?.distanceInMiles?.toFixed(2) ?? '-'} mi`} />
    </Box>
  );
};

const getLastUpdatedTime = (pollutantData: PollutantData) => {
  return pollutantData?.utcTimeStamp ? moment.utc(pollutantData.utcTimeStamp).fromNow() : 'N/A';
};

export default function AirQualityDetailsModal({
  locationId,
  open,
  handleClose,
  handleOpen = () => {},
}: AirQualityDetailsModalProps) {
  const { data: airQualityData } = useGetAirQualityForLocationQuery(locationId ?? skipToken);

  const pm25Data = airQualityData?.find(x => x.pollutantName === AqiType.PM25);
  const pm10Data = airQualityData?.find(x => x.pollutantName === AqiType.PM10);
  const o3Data = airQualityData?.find(x => x.pollutantName === AqiType.O3);

  return (
    <Modal modalState={{ open, handleClose, handleOpen }}>
      <Box>
        <Typography component='h5' fontSize={24} marginBottom={2}>
          Air Quality Details
        </Typography>

        {[pm25Data, pm10Data, o3Data].map(pollutantData => {
          if (pollutantData) {
            return (
              <Box marginBottom={4}>
                <Card>
                  <Grid container justifyContent={'space-between'}>
                    <Box flexBasis={'45%'}>
                      <Typography variant='h6' fontWeight={'bold'}>
                        {pollutantData.pollutantName}
                      </Typography>
                    </Box>
                    <Box alignSelf='right'>
                      <Typography variant='caption'>{'Updated ' + getLastUpdatedTime(pollutantData)}</Typography>
                    </Box>
                  </Grid>

                  <Grid container justifyContent={'space-between'}>
                    <Box flexBasis={'45%'}>
                      <PollutantDetails pollutant={pollutantData} />
                    </Box>
                    <Box justifyContent={'center'} alignContent={'center'} flexBasis={'45%'}>
                      <AirQualityGauge pollutantData={pollutantData} width={175} />
                    </Box>
                  </Grid>
                </Card>
              </Box>
            );
          }
          return null;
        })}
      </Box>

      <Box display='flex' justifyContent='flex-end' width='100%'>
        <Button type='clear' onClick={handleClose}>
          <Typography variant='body1'>Close</Typography>
        </Button>
      </Box>
    </Modal>
  );
}
