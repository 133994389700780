import { Autocomplete, Grid, Typography, useTheme, TextField, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button, Page } from '@perry-weather/component-library';
import { useAuth } from 'oidc-react';
import LiveCameraCard from '../Components/LiveCamera/LiveCameraCard';
import {
  LiveCamera as CameraFeed,
  useCustomerQuery,
  useLazyAllCameraFeedsQuery,
  useLazyCameraFeedsForCustomerQuery,
  useLazyConsumedMinutesForCustomerQuery,
  useUserQuery,
} from '../Services/API';
import { Redirect, useLocation } from 'wouter';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      '&.Mui-focused': {
        backgroundColor: theme.palette.primary.main + ' !important',
        borderColor: 'transparent',
        color: theme.palette.text.primary,
      },
    },
  })
);

const convertToHours = (totalMinutes: number): { hours: number; minutes: number } => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);
  return { hours, minutes };
};

export function LiveCamera(props: any) {
  const { userData: authUser } = useAuth();
  const { data: user } = useUserQuery();
  const [getCameraFeedsForCustomer, customerCameraFeedsResult] = useLazyCameraFeedsForCustomerQuery();
  const { data: customerCameraFeeds } = customerCameraFeedsResult;
  const [getAllCameraFeeds, allCameraFeedsResult] = useLazyAllCameraFeedsQuery();
  const [getConsumedMinutesForCustomer, consumedMinutesResult] = useLazyConsumedMinutesForCustomerQuery({
    pollingInterval: 60000,
  });
  const { data: consumedMinutesData, isSuccess: consumedMinutesSuccess } = consumedMinutesResult;
  const { data: customer, isSuccess: customerFetchSuccess } = useCustomerQuery(user ? user.customerId : 0, {
    skip: !user,
  });
  const { data: allCameraFeeds } = allCameraFeedsResult;
  const [activeCamera, setActiveCamera] = useState<CameraFeed>();
  const [disconnectMessage, setDisconnectMessage] = useState<string>('');
  const styles = useStyles(useTheme());
  const theme = useTheme();
  const [location, setLocation] = useLocation();

  const { hours: consumedHours, minutes: consumedMinutes } = convertToHours(consumedMinutesData || 0);
  const { hours: allowedHours, minutes: allowedMinutes } = convertToHours(customer?.liveStreamMinutes || 0);

  useEffect(() => {
    if (user && user.customerId) {
      if (userIsSuperAdmin) getAllCameraFeeds();
      else getCameraFeedsForCustomer(user.customerId);
      getConsumedMinutesForCustomer(user.customerId);
    }
  }, [user]);

  const hasLiveCameraAccess =
    authUser?.profile?.permissions?.includes('live_camera.access') &&
    (authUser?.profile?.Role === 'Admin' ||
      authUser?.profile?.Role === 'Super Admin' ||
      authUser?.profile?.Role === 'Assistant Admin');
  const userIsSuperAdmin = authUser?.profile?.Role === 'Super Admin';

  let cameraSelectOptions: { id: string; label: string }[] = [];
  let cameras: CameraFeed[] | undefined = [];
  if (userIsSuperAdmin) {
    cameraSelectOptions = allCameraFeeds
      ? allCameraFeeds.map(camera => {
          return { id: camera.id, label: camera.name };
        })
      : [];
    cameras = allCameraFeeds;
  } else {
    cameraSelectOptions = customerCameraFeeds
      ? customerCameraFeeds.map(camera => {
          return { id: camera.id, label: camera.name };
        })
      : [];
    cameras = customerCameraFeeds;
  }

  cameraSelectOptions.unshift({ id: '', label: 'Select Camera' });

  const cameraSelectReady = cameraSelectOptions.length > 1 && consumedMinutesResult.isSuccess;

  const handleCameraSelect = (id: string | undefined) => {
    setDisconnectMessage('');
    if (id === undefined) {
      setActiveCamera(undefined);
      return;
    }
    setActiveCamera(
      cameras?.find(camera => {
        return camera.id === id;
      })
    );
  };

  const outOfMinutes = () => {
    if (!customer || !customer.liveStreamMinutes || customer.liveStreamMinutes === 0) return true;
    if (consumedMinutesData == undefined) return true;
    return consumedMinutesData >= customer.liveStreamMinutes;
  };

  const durationFontColor = outOfMinutes() ? theme.palette.error.main : theme.palette.info.main;

  if (!hasLiveCameraAccess) return <Redirect to='/Unauthorized' />;

  return (
    <Page
      title={
        <Typography variant='h3' color='textPrimary' noWrap>
          Live Camera
        </Typography>
      }
      pageAction={
        <Grid container alignItems={'center'} justifyContent={'flex-end'}>
          <Grid item xs={6}>
            <Autocomplete
              sx={{ width: '100%' }}
              classes={{ option: styles.option }}
              options={cameraSelectOptions}
              renderInput={params => <TextField {...params} />}
              value={
                activeCamera ? { id: activeCamera.id, label: activeCamera.name } : { id: '', label: 'Select Camera' }
              }
              onChange={(e, value) => handleCameraSelect(value?.id)}
              placeholder={cameraSelectReady ? 'Select Camera' : 'Fetching available cameras...'}
              disabled={!cameraSelectReady || outOfMinutes()}
            />
          </Grid>
          <Grid item xs={6}>
            <Button disabled={!customer} onClick={() => setLocation(`/LiveCamera/Logs/${customer?.id}`)}>
              Camera Logs
            </Button>
          </Grid>
        </Grid>
      }>
      {activeCamera ? (
        <Grid container width={'100%'} flexWrap='wrap' spacing={2} alignItems='center' justifyContent={'center'}>
          <LiveCameraCard
            camera={activeCamera}
            setActiveCamera={setActiveCamera}
            setDisconnectMessage={setDisconnectMessage}
          />
        </Grid>
      ) : (
        <Grid
          container
          margin={1}
          alignContent='center'
          alignItems='center'
          display='flex'
          flexDirection='column'
          justifyContent='center'>
          {customerFetchSuccess && consumedMinutesSuccess && (
            <Grid
              item
              container
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              maxWidth={'90%'}>
              <Grid item>
                {disconnectMessage && (
                  <Typography color={theme.palette.warning.main} variant='h4' marginTop={20} marginBottom={-20}>
                    {disconnectMessage}
                  </Typography>
                )}
                {!outOfMinutes() && (
                  <Typography variant='h4' marginTop={20} marginBottom={-20}>
                    Select a camera.
                  </Typography>
                )}
                <Typography variant='h4' marginTop={20}>
                  You have used
                  <span style={{ color: durationFontColor }}>
                    {' '}
                    {consumedHours}h {consumedMinutes}m{' '}
                  </span>
                  of
                  <span style={{ color: durationFontColor }}>
                    {' '}
                    {allowedHours}h {allowedMinutes > 0 && allowedMinutes + 'm'}{' '}
                  </span>
                  this month.
                </Typography>
              </Grid>
              {(customerCameraFeedsResult.isError || allCameraFeedsResult.isError) && (
                <Grid item>
                  <Typography variant='h6' maxWidth={'700px'} marginTop={'20px'}>
                    Unable to load cameras.
                  </Typography>
                </Grid>
              )}
              {customerCameraFeedsResult.isSuccess && customerCameraFeeds?.length === 0 && (
                <Grid item>
                  <Typography variant='h6' maxWidth={'700px'} marginTop={'20px'}>
                    Your organization does not have a live camera feed.
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Page>
  );
}
