import { Grid, Typography } from '@mui/material';
import { AQIIndex, PollutantData } from '../../../Services/API';
import ProgressCircle from '../../Animated/ProgressCircle';

type AirQualityGaugeProps = {
  pollutantData: PollutantData;
  width?: number;
};

const AQI_RANGE_MAX = 500;
const AQI_RANGE_MIN = 0;

const getOzoneAQI = (o3: PollutantData) => {
  if (o3?.aqiData.oneHourIndex?.value && o3?.aqiData.eightHourIndex?.value) {
    if (o3?.aqiData.oneHourIndex.value >= o3?.aqiData.eightHourIndex.value) {
      return o3?.aqiData.oneHourIndex;
    } else {
      return o3?.aqiData.eightHourIndex;
    }
  } else if (o3?.aqiData.oneHourIndex?.value) {
    return o3?.aqiData.oneHourIndex;
  } else {
    return o3?.aqiData.eightHourIndex;
  }
};

export default function AirQualityGauge({ pollutantData, width = 100 }: AirQualityGaugeProps) {
  let aqiIndex: AQIIndex | undefined;

  if (pollutantData.pollutantName === 'Ozone') {
    aqiIndex = pollutantData.aqiData.nowCastIndex ? pollutantData.aqiData.nowCastIndex : getOzoneAQI(pollutantData);
  } else {
    aqiIndex = pollutantData.aqiData.nowCastIndex
      ? pollutantData.aqiData.nowCastIndex
      : pollutantData.aqiData.twentyFourHourIndex;
  }

  return (
    <Grid container flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <ProgressCircle
        width={width}
        value={aqiIndex?.value}
        max={AQI_RANGE_MAX}
        min={AQI_RANGE_MIN}
        color={aqiIndex?.color}
      />
      <Typography marginTop={-3} variant='h5'>
        {aqiIndex?.value}
      </Typography>
      <Typography variant='h6' textAlign='center'>{aqiIndex?.shortDescription ?? ''}</Typography>
    </Grid>
  );
}
