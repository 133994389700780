import { Lock } from '@mui/icons-material';
import { Grid, useTheme, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSitePoliciesQuery } from '../../../Services/API';
import { Label } from '../../Base';
import { Slider } from '@perry-weather/component-library';

interface AllClearSliderProps {
  onChange?: (value: number) => void;
  value: number;
}

export default function AllClearSlider(props: AllClearSliderProps) {
  const { value, onChange } = props;

  const [allClearData, setAllClearData] = useState({ value: 10, disabled: false });

  const theme = useTheme();
  const { data: userPolicies } = useSitePoliciesQuery();

  useEffect(() => {
    if (userPolicies && userPolicies.length > 0) {
      let lrPolicies = userPolicies.filter(x => x.policyTypeShortName!.substr(0, 2) === 'LR');
      let policyAllClearValue = value ? value : 10;
      let disabledByPolicy = false;

      if (lrPolicies && lrPolicies.length > 0) {
        if (lrPolicies.length > 1)
          policyAllClearValue = lrPolicies
            .sort((x, y) => (x!.policyTypeShortName?.substr(2, 3)! < y!.policyTypeShortName?.substr(2, 3)! ? 1 : -1))!
            .pop()!.allClearMinutes;
        else if (lrPolicies.length > 0) policyAllClearValue = lrPolicies!.pop()!.allClearMinutes;

        disabledByPolicy = true;
      }
      setAllClearData({ value: policyAllClearValue, disabled: disabledByPolicy });
    }
  }, [value, userPolicies]);

  return (
    <Grid container style={{ marginBottom: 16 }}>
      <Grid container item md={4} sm={12}>
        <Grid item sm={2} md={3} display='flex' justifyContent='center'>
          {allClearData.disabled && (
            <Box justifySelf='center' flexDirection='column' display='flex' justifyContent='center'>
              <Lock htmlColor={theme.palette.text.secondary} />
            </Box>
          )}
        </Grid>
        <Grid item xs style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Label
            labelColor={allClearData.disabled ? 'textSecondary' : undefined}
            text='All-Clear Timer'
            labelVariant='body1'
          />
        </Grid>
      </Grid>
      <Grid container item md sm={12}>
        <Slider
          disabled={allClearData.disabled}
          onChangeCommit={onChange}
          value={allClearData.value}
          min={10}
          step={10}
          max={60}
          displayValue
          unit='min'
          backgroundColor={theme.palette.text.primary}
        />
      </Grid>
    </Grid>
  );
}

export { AllClearSlider };
