import { Box, Grid, Link, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import { Card } from '@perry-weather/component-library';
import { useGetAirQualityForLocationQuery } from '../../../Services/API';
import { useAppSelector } from '../../../app/hooks';
import { getSelectedLocation } from '../../../features/dash/dashSlice';
import { useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AqiType } from '../../../Constants/AqiConstants';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AirQualityDetailsModal from './AirQualityDetails';
import AirQualityGauge from './AqiGauge';

export function AirQualityCard() {
  const selectedLocation = useAppSelector(getSelectedLocation);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const {
    data: airQualityData,
    isFetching: isAirQualityFetching,
    isLoading: isAirQualityLoading,
  } = useGetAirQualityForLocationQuery(selectedLocation?.id ?? skipToken);

  const [isSevere, setIsSevere] = useState(false); //Will need to hook this up once we have policies in place

  const pm25Data = airQualityData?.find(x => x.pollutantName === AqiType.PM25);
  const pm10Data = airQualityData?.find(x => x.pollutantName === AqiType.PM10);
  const o3Data = airQualityData?.find(x => x.pollutantName === AqiType.O3);

  const validPollutants = [pm25Data, pm10Data, o3Data].filter(Boolean);

  return (
    <Card
      header='Air Quality'
      scary={isSevere}
      action={
        <Link
          onClick={() => setDetailsModalOpen(true)}
          style={{
            display: airQualityData && airQualityData.length > 0 ? 'flex' : 'none',
            textDecoration: 'none',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          aria-disabled={airQualityData && airQualityData.length > 0}>
          <Typography>Details</Typography>
          <ArrowForwardIcon sx={{ width: 20, height: 16 }} />
        </Link>
      }>
      {detailsModalOpen && (
        <AirQualityDetailsModal
          open={detailsModalOpen}
          handleClose={() => setDetailsModalOpen(false)}
          locationId={selectedLocation?.id as string}
        />
      )}
      {isAirQualityFetching || isAirQualityLoading ? (
        <Box display='flex' justifyContent='space-evenly'>
          <Skeleton animation={false} variant='rectangular' width='100%' height={110} />
          <Skeleton animation={false} variant='rectangular' width='100%' height={110} />
          <Skeleton animation={false} variant='rectangular' width='100%' height={110} />
        </Box>
      ) : airQualityData && airQualityData.length > 0 ? (
        <>
          <Grid
            spacing={2}
            container
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            justifyContent={'space-around'}>
            {validPollutants.map(pollutantData => {
              if (pollutantData) {
                return (
                  <Grid
                    item
                    sx={{
                      flexBasis: validPollutants.length > 1 ? '30%' : '100%',
                    }}>
                    <Typography variant='h6'>{pollutantData.pollutantName}</Typography>
                    <AirQualityGauge pollutantData={pollutantData} />
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
        </>
      ) : (
        <Typography>No Data</Typography>
      )}
    </Card>
  );
}
export default AirQualityCard;
