import { Typography } from '@mui/material';
import { useAuth } from 'oidc-react';
import { AirQualityTable } from '../Components/AirQuality/AirQualityTable';
import { Page } from '@perry-weather/component-library';
import { useAirQualitySensorsQuery } from '../Services/API';
import Unauthorized from './Unauthorized';

export function AirQuality(props: any) {
  const { data: sensors } = useAirQualitySensorsQuery();
  const { userData: user } = useAuth();
  var hasAQI =
    user?.profile?.permissions?.includes('aqi.access') &&
    (user?.profile?.Role === 'Assistant Admin' ||
      user?.profile?.Role === 'Admin' ||
      user?.profile?.Role === 'Super Admin');

  if (!hasAQI) return <Unauthorized />;
  else
    return (
      <Page
        docTitle='Air Quality'
        title={
          <Typography variant='h3' color='textPrimary' noWrap>
            Air Quality
          </Typography>
        }>
        {sensors && <AirQualityTable data={sensors} />}
      </Page>
    );
}
