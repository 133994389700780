/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { HardwareCard } from '../Components/Hardware/HardwareCard';
import { Button, Menu, PWMenuItem, SearchField, Page, Pagination } from '@perry-weather/component-library';
import { Hardware as HardwareItem, OWS, PWError, useHardwareQuery } from '../Services/API';
import { useEffect, useState } from 'react';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { HardwareFilterTypes, HardwareTypes, SirenTests } from '../Constants/Constants';
import { SendPAMessageModal } from '../Components/Hardware/SendPAMessageModal';
import { useTheme } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { openToast } from '../features/toast/toastSlice';
import { CellularNetworkIcon } from '../Assets/CellularNetwork';
import { BatchTestModal } from '../Components/Hardware/BatchTestModal';
import { useAuth } from 'oidc-react';
import { Redirect } from 'wouter';

const HARDWARE_PER_PAGE = 15;

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function isHardwareType(hardware: HardwareItem, type: HardwareFilterTypes) {
  if (hardware.modelId === HardwareTypes.OWX) return true;
  else if (HardwareFilterTypes.OWS === type && hardware.modelId === HardwareTypes.OWS) return true;
  else if (HardwareFilterTypes.WXS === type && hardware.modelId === HardwareTypes.WXS) return true;
  else return false;
}

const skeletonCards = () => {
  var cards: JSX.Element[] = [];

  for (var i = 0; i < 15; i++) {
    cards.push(
      <Grid item key={i}>
        <Skeleton variant='rectangular' width={380} height={482}></Skeleton>
      </Grid>
    );
  }
  return cards;
};

export function Hardware() {
  const theme = useTheme();
  const {
    data: hardwareList,
    isLoading,
    isError,
    error,
  } = useHardwareQuery(undefined, {
    pollingInterval: 300000,
  });
  const dispatch = useDispatch();

  const { userData: user } = useAuth();
  var hasPAFullAccess = user?.profile?.permissions?.includes('pa.full_access');

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState<HardwareItem[]>(hardwareList || []);
  const [searchString, setSearchString] = useState('');
  const [hardwareFilterTypes, setHardwareFilterTypes] = useState<HardwareFilterTypes>(HardwareFilterTypes.ALL);
  const [batch, setBatch] = useState<OWS[]>([]);

  const [openPAModal, setOpenPAModal] = useState(false);
  const [openBatchActionModal, setOpenBatchActionModal] = useState(false);
  const [testType, setTestType] = useState<SirenTests>();

  const [anchorElView, setAnchorElView] = useState(null);
  const [anchorElBatch, setAnchorElBatch] = useState(null);

  const openView = Boolean(anchorElView);
  const openBatch = Boolean(anchorElBatch);

  const handleViewClick = (event: any) => {
    setAnchorElView(event.currentTarget);
  };

  const handleBatchClick = (event: any) => {
    setAnchorElBatch(event.currentTarget);
  };

  const handleViewFilterClose = (event: any) => {
    if (filteredData) {
      setAnchorElView(null);
      var typeName = event.target.textContent;
      if (typeName !== '') {
        setHardwareFilterTypes(typeName);
      }
    }
  };

  const handleBulkAction = (event: any) => {
    setAnchorElBatch(null);

    let batchOption = event.target.textContent;

    switch (batchOption) {
      case SirenTests.Delay:
        setTestType(SirenTests.Delay);
        setOpenBatchActionModal(true);
        break;
      case SirenTests.AllClear:
        setTestType(SirenTests.AllClear);
        setOpenBatchActionModal(true);
        break;
      case SirenTests.Light:
        setTestType(SirenTests.Light);
        setOpenBatchActionModal(true);
        break;
      case SirenTests.PAMessage:
        setOpenPAModal(true);
        break;
    }
  };

  const handleSearch = (str: string) => {
    setSearchString(str);
  };

  const handleSelectHardware = (isSelected: boolean, siren: OWS) => {
    if (isSelected) {
      if (
        batch.find(value => {
          return value.id === siren.id;
        }) === undefined
      ) {
        let newBatch = batch.concat(siren);
        setBatch(newBatch);
      }
    } else {
      let newBatch = batch.filter(value => {
        return value.id !== siren.id;
      });
      setBatch(newBatch);
    }
  };

  const isSelected = (sirenId: string) => {
    if (batch.length === 0) return false;

    return (
      batch.find(value => {
        return value.id === sirenId;
      }) !== undefined
    );
  };

  const hardwareCards = (list: HardwareItem[]) => {
    var startIndex = (currentPage - 1) * HARDWARE_PER_PAGE;
    var endIndex = currentPage * HARDWARE_PER_PAGE < list.length ? currentPage * HARDWARE_PER_PAGE : list.length;
    var partialList = filteredData?.slice(startIndex, endIndex);

    return partialList ? (
      partialList.map(hardware => {
        return (
          <Grid item key={hardware.id}>
            <HardwareCard selectHardware={handleSelectHardware} isSelected={isSelected} hardware={hardware} />
          </Grid>
        );
      })
    ) : (
      <></>
    );
  };

  const NoHardware = () => (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}>
      <Grid
        container
        margin={1}
        alignContent='center'
        alignItems='center'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        spacing={1}>
        <Grid item>
          <CellularNetworkIcon width={85} height={85} color={theme.palette.primary.main} />
        </Grid>

        <Grid item>
          <Typography variant='h4' noWrap>
            You don't have any hardware added
          </Typography>
        </Grid>

        <Grid item maxWidth={490}>
          <Typography variant='body1'>
            Please reach out to your team admin to assist with adding or assigning hardware properly.
          </Typography>
        </Grid>

        <Grid item maxWidth={510}>
          <Button
            onClick={() => {
              window.open('mailto:support@perryweather.com');
            }}>
            Contact us
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  useEffect(() => {
    if (hardwareList) {
      let filteredRows: HardwareItem[] = hardwareList;
      if (HardwareFilterTypes.ALL !== hardwareFilterTypes) {
        filteredRows = filteredRows.filter(value => {
          return isHardwareType(value, hardwareFilterTypes);
        });
      }

      if (searchString.length > 2 || searchString === '') {
        const searchRegex = new RegExp(escapeRegExp(searchString), 'i');
        filteredRows = filteredRows.filter((row: any) => {
          return Object.keys(row).some((field: any) => {
            return searchRegex.test(row[field] !== null ? row[field].toString() : true);
          });
        });
      }

      setFilteredData(filteredRows);
      setCurrentPage(1);
    }
  }, [hardwareFilterTypes, searchString, hardwareList]);

  useEffect(() => {
    if (isError && error) {
      if ((error as PWError)?.data?.responseException?.message) {
        dispatch(openToast({ variant: 'error', header: (error as PWError)?.data.responseException.message }));
      } else {
        dispatch(openToast({ variant: 'error', header: 'Failed To Get Hardware' }));
      }
    }
  }, [isError, error]);

  const pageAction = () => {
    return [
      <Grid item xs={12} sm={12} md display='flex' ml={1} key='search'>
        <SearchField handleSearch={handleSearch} disabled={isLoading}></SearchField>
      </Grid>,
      <Grid item display='flex' flex='0 0 auto' key='bulk'>
        <Button onClick={handleBatchClick} type='clear' disabled={batch.length < 2}>
          <Typography>Bulk Actions {batch.length > 0 ? `(${batch.length})` : ''}</Typography>
          <ArrowDropDownOutlinedIcon />
        </Button>
      </Grid>,
      <Grid item display='flex' flex='0 0 auto' key='view'>
        <Button onClick={handleViewClick} type='clear' disabled={isLoading}>
          <Typography>View: {hardwareFilterTypes}</Typography>
          <ArrowDropDownOutlinedIcon />
        </Button>
      </Grid>,
      hasPAFullAccess && (
        <Grid item display='flex' flex='0 0 auto' key='message'>
          <Button onClick={() => setOpenPAModal(true)}>
            <Typography variant='body1'> Send PA Message </Typography>
          </Button>
        </Grid>
      ),
    ];
  };

  const getBatchPAIds = () => {
    var paIds: string[] = [];

    batch.forEach(value => {
      if (value.afterSirenPaId) paIds.push(value.afterSirenPaId);
    });

    return paIds;
  };

  const resetTesting = () => {
    setOpenBatchActionModal(false);
    setTestType(undefined);
  };

  if (
    !(
      user?.profile?.Role === 'Assistant Admin' ||
      user?.profile?.Role === 'Admin' ||
      user?.profile?.Role === 'Super Admin'
    )
  ) {
    return <Redirect to='/Unauthorized' />;
  }

  return hardwareList && hardwareList.length === 0 ? (
    <NoHardware />
  ) : isLoading || isError ? (
    <Page
      docTitle='Hardware'
      title={
        <Typography variant='h3' color='textPrimary' noWrap>
          On-Site Hardware
        </Typography>
      }>
      <Grid container justifyContent='center' display='flex'>
        <Grid container display='flex' justifyContent='space-around' rowSpacing={4} columnSpacing={1}>
          {skeletonCards()}
        </Grid>
      </Grid>
    </Page>
  ) : (
    <Page
      docTitle='Hardware'
      title={
        <Typography variant='h3' color='textPrimary' noWrap>
          On-Site Hardware
        </Typography>
      }
      pageAction={pageAction()}
      titleContainerProps={{
        width: 'auto',
        display: 'flex',
        flexBasis: 'auto',
        md: true,
        lg: true,
        flex: '0 0 auto !important',
      }}
      actionContainerProps={{
        container: true,
        display: 'flex',
        flexBasis: 'auto',
        md: true,
        lg: true,
        flex: '0 1 auto',
      }}>
      <Grid container justifyContent='center' display='flex'>
        <Grid container display='flex' justifyContent='space-around' rowSpacing={4} columnSpacing={1}>
          {hardwareCards(filteredData)}
        </Grid>

        <Box marginTop={'40px'}>
          <Pagination
            count={Math.ceil(filteredData.length / HARDWARE_PER_PAGE)}
            page={currentPage}
            onChange={(event, page) => {
              setCurrentPage(page);
            }}
          />
        </Box>
      </Grid>
      <Menu open={openView} anchorEl={anchorElView} onClose={handleViewFilterClose}>
        <PWMenuItem onClick={handleViewFilterClose}>{HardwareFilterTypes.ALL}</PWMenuItem>
        <PWMenuItem onClick={handleViewFilterClose}>{HardwareFilterTypes.OWS}</PWMenuItem>
        <PWMenuItem onClick={handleViewFilterClose}>{HardwareFilterTypes.WXS}</PWMenuItem>
      </Menu>
      <Menu open={openBatch} anchorEl={anchorElBatch} onClose={handleBulkAction}>
        <PWMenuItem onClick={handleBulkAction}>{SirenTests.Delay}</PWMenuItem>
        <PWMenuItem onClick={handleBulkAction}>{SirenTests.AllClear}</PWMenuItem>
        <PWMenuItem onClick={handleBulkAction}>{SirenTests.Light}</PWMenuItem>
        {hasPAFullAccess && <PWMenuItem onClick={handleBulkAction}>{SirenTests.PAMessage}</PWMenuItem>}
      </Menu>
      <SendPAMessageModal open={openPAModal} handleClose={setOpenPAModal} batchPASystems={getBatchPAIds()} />
      <BatchTestModal open={openBatchActionModal} testType={testType} resetTesting={resetTesting} batchSirens={batch} />
    </Page>
  );
}

export default Hardware;
