import { Close } from '@mui/icons-material';
import { Grid, Typography, Paper, PaperProps, Button, styled } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { memo } from 'react';

export type BreadVariant = 'error' | 'warn' | 'info' | 'success';

interface StyledPaperProps extends PaperProps {
    breadvariant: BreadVariant;
    theme: Theme;
}

interface BreadProps {
    variant: BreadVariant;
    header?: string;
    icon?: JSX.Element;
    message?: string;
    closeable?: boolean;
    onClose?: () => void;
}

const StyledPaper = styled(Paper, {
    shouldForwardProp: prop => prop !== 'success'
})<StyledPaperProps>(({ breadvariant, theme }) => ({
    width: '100%',
    padding: '8px 16px 8px 16px',
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    ...(breadvariant === 'error' && {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        backgroundColor: theme.palette.background.error
    }),
    ...(breadvariant === 'warn' && {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
        backgroundColor: theme.palette.background.warning
    }),
    ...(breadvariant === 'info' && {
        color: theme.palette.info.light,
        borderColor: theme.palette.info.light,
        backgroundColor: theme.palette.info.dark
    }),
    ...(breadvariant === 'success' && {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        backgroundColor: theme.palette.background.success
    })
}));

export const Bread = memo(function Bread(props: BreadProps) {
    const { variant, header, message, icon, closeable } = props;
    const theme = useTheme();

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <StyledPaper breadvariant={variant} theme={theme} sx={{ display: 'flex', alignItems: 'start' }}>
            <Grid container columnSpacing={4} alignItems="center">
                {icon && (
                    <Grid item xs container alignItems="center">
                        {icon}
                    </Grid>
                )}
                <Grid container item xs="auto" flexDirection="column" alignItems="start">
                    {header && (
                        <Typography textAlign="left" variant="subtitle1">
                            {header}
                        </Typography>
                    )}
                    {message && (
                        <Typography textAlign="left" variant="body2" maxWidth="240px">
                            {message}
                        </Typography>
                    )}
                </Grid>
                {closeable && (
                    <Grid item xs>
                        <Button color="inherit" onClick={onClose}>
                            <Close />
                        </Button>
                    </Grid>
                )}
            </Grid>
        </StyledPaper>
    );
});
