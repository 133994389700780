import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Button, Page } from '@perry-weather/component-library';
import { AddRounded } from '@mui/icons-material';
import { Policy, usePoliciesQuery, useSitePoliciesQuery } from '../Services/API';
import { PolicyCard } from '../Components/Policies/PolicyCard';
import { PolicyModal } from '../Components/Policies/PolicyModal';
import { Redirect } from 'wouter';
import { useAuth } from 'oidc-react';
import { FactCheck as PolicyIcon } from '@mui/icons-material';

export function Policies() {
  const { refetch: refetchPoliciesBySite } = useSitePoliciesQuery();
  const [policyModalOpen, setPolicyModalOpen] = useState(false);
  const { userData: user } = useAuth();

  const cantViewCustomerPolicies: boolean = !(user?.profile?.Role === 'Admin' || user?.profile?.Role === 'Super Admin');

  const { data: customerPolicies } = usePoliciesQuery(undefined, { skip: cantViewCustomerPolicies });
  const { data: userPolicies } = useSitePoliciesQuery(undefined, { skip: user?.profile?.Role !== 'Assistant Admin' });

  const defaultPolicy: Policy = {
    id: '',
    policyType: 0,
    policyTypeName: '',
    threshold: 0,
    reAlertMinutes: 10,
    allClearMinutes: 30,
    customerId: 0,
    sites: [],
    precision: 0,
    limit: 0,
    notificationActions: [
      { id: '', triggerId: '', allClear: false, message: '' },
      { id: '', triggerId: '', allClear: true, message: '' },
    ],
    minAllClear: 0,
    allGroups: false,
  };

  const [selectedPolicy, setSelectedPolicy] = useState<Policy>(defaultPolicy);
  const [policies, setPolicies] = useState<Policy[]>([]);

  useEffect(() => {
    if (customerPolicies) setPolicies(customerPolicies);
    else if (userPolicies) setPolicies(userPolicies);
  }, [customerPolicies, userPolicies]);

  const handleModalClose = () => {
    refetchPoliciesBySite();
    setSelectedPolicy(defaultPolicy);
    setPolicyModalOpen(false);
  };

  if (
    !(
      user?.profile?.Role === 'Admin' ||
      user?.profile?.Role === 'Super Admin' ||
      user?.profile?.Role === 'Assistant Admin'
    )
  ) {
    return <Redirect to='/Unauthorized' />;
  }

  const NoPolicies = () => (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}>
      <Grid
        container
        margin={1}
        alignContent='center'
        alignItems='center'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        spacing={1}>
        <Grid item>
          <PolicyIcon color='primary' style={{ width: 85, height: 85 }} />
        </Grid>

        <Grid item>
          <Typography variant='h4' noWrap>
            You don't have any policies added
          </Typography>
        </Grid>

        <Grid item maxWidth={490}>
          <Typography variant='body1'>Add a policy to continue.</Typography>
        </Grid>

        <Grid item maxWidth={510}>
          <Button
            onClick={e => {
              setSelectedPolicy(defaultPolicy);
              setPolicyModalOpen(true);
            }}>
            <AddRounded />
            <Typography>Add Policy</Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  // console.debug(policies?.length)
  return (
    <>
      {policies && policies.length === 0 ? (
        <NoPolicies />
      ) : (
        <Page
          docTitle='Policies'
          title={
            <Typography variant='h3' color='textPrimary' noWrap>
              Policies
            </Typography>
          }
          subheader={
            <Typography variant='body1' color='textSecondary'>
              Policies enforce consistent notification settings across all users or specific user groups.
              {/*<Link underline='hover' href='https://google.com'>Learn more</Link>*/}
            </Typography>
          }
          pageAction={
            user?.profile?.Role === 'Admin' || user?.profile?.Role === 'Super Admin' ? (
              <Button
                onClick={e => {
                  setSelectedPolicy(defaultPolicy);
                  setPolicyModalOpen(true);
                }}>
                <AddRounded />
                <Typography>Add Policy</Typography>
              </Button>
            ) : undefined
          }>
          <Grid container spacing={4}>
            {policies &&
              policies.map(d => (
                <PolicyCard
                  policy={d}
                  onEdit={e => {
                    setSelectedPolicy(e);
                    setPolicyModalOpen(true);
                  }}
                  key={d.id}
                />
              ))}
          </Grid>
        </Page>
      )}
      <PolicyModal
        policy={selectedPolicy}
        modalState={{ open: policyModalOpen, handleClose: handleModalClose, handleOpen: () => false }}
      />
    </>
  );
}

export default Policies;
