import { WarningAmberOutlined } from '@mui/icons-material';
import { Box, Grid, Typography, useTheme, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { OWS } from '../../Services/API';
import { SubFormContainer } from '../Base';
import { Button, Modal, ModalState } from '@perry-weather/component-library';

interface PolicyUpdatePromptProps {
  updatePolicy: React.MouseEventHandler;
  modalState: ModalState;
  customerSirens: OWSWithSettings[] | undefined;
}

interface OWSWithSettings extends OWS {
  sirenSettings?: {
    radius: number;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.down('sm')]: {},
    },
    yellowCircle: {
      backgroundColor: theme.palette.background.warning,
      borderColor: theme.palette.warning.main,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '50%',
      height: 40,
      width: 40,
    },
  })
);

export function PolicyUpdatePrompt(props: PolicyUpdatePromptProps) {
  const { updatePolicy, modalState, customerSirens } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  let sirensWithSettings: OWSWithSettings[] = [];
  if (customerSirens) sirensWithSettings = customerSirens.filter(siren => siren.sirenSettings);

  return (
    <Modal modalState={modalState}>
      <Grid container item style={{ marginBottom: 8 }}>
        <Grid item md={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box display='flex' justifyContent='center' width='100%'>
            <Box
              component='span'
              display='flex'
              flexDirection='column'
              justifyContent='center'
              className={classes.yellowCircle}>
              <Box display='flex' justifyContent='center' width='100%'>
                <WarningAmberOutlined htmlColor={theme.palette.warning.main} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={10} xs={12}>
          <Typography variant='h5'>Warning: Updating Lightning Zone 1</Typography>
        </Grid>
      </Grid>
      <Grid container item style={{ marginBottom: 16 }}>
        <SubFormContainer>
          <Typography> Changes to policy radius are not reflected in siren hardware settings.</Typography>
          <Typography>Contact support@perryweather.com to update siren hardware.</Typography>
        </SubFormContainer>
      </Grid>
      {sirensWithSettings && sirensWithSettings.length > 0 ? (
        <Grid item>
          <Grid item>
            <Typography variant='h5'>Siren Hardware Radius</Typography>
          </Grid>
          <hr></hr>
          {sirensWithSettings.map(siren => (
            <Grid item container justifyContent={'space-between'}>
              <Grid item>
                <Grid>{siren.locationName}</Grid>
              </Grid>
              <Grid item>
                <Grid>{siren.sirenSettings && siren.sirenSettings.radius} mi</Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid></Grid>
      )}
      <Grid mt={2} container item>
        <Grid item md={6} xs={false} />
        <Grid item container spacing={1} md={6} xs={12}>
          <Grid item xs={6}>
            <Button type='clear' onClick={modalState.handleClose}>
              <Typography variant='body1'>Cancel</Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button type={'primary'} onClick={updatePolicy}>
              <Typography variant='body1'>Update</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
