import { Typography } from '@mui/material';
import { Button, Card } from '@perry-weather/component-library';
import PermissionGate from '../../Shared/PermissionGate';
import { CustomerEntitlements } from '../../../Constants/Constants';
import { useState } from 'react';
import { useSinglewireConfigQuery, useUserQuery } from '../../../Services/API';
import { ConnectionModal } from './ConnectionModal';
import { useLocation } from 'wouter';

export const SinglewireIntegrationCard = () => {
  const [connectionModalOpen, setConnectionModalOpen] = useState(false);
  const setLocation = useLocation()[1];
  const { data: user, isSuccess: isUserLoaded } = useUserQuery();
  const { data: existingSinglewireConfig } = useSinglewireConfigQuery(user?.customerId ?? 0, { skip: !isUserLoaded });
  const hasSinglewireConfig = !!existingSinglewireConfig;

  const onSinglewireContactClick = () => {
    console.log('Contact for Pricing');
  };

  const onSinglewireConfigureClick = () => {
    setConnectionModalOpen(true);
  };

  const onSinglewireManageClick = () => {
    setLocation('/Integrations/Singlewire');
  };

  return (
    <>
      <Card header='Singlewire - InformaCast'>
        <img src='/singlewire.png' alt='Singlewire Logo' style={{ borderRadius: 4, marginBottom: 24 }} />
        <Typography variant='body1' color='textPrimary' style={{ marginBottom: 16 }}>
          Send Perry Weather policy alerts to your organization members through InformaCast
        </Typography>

        <PermissionGate
          allowedEntitlements={[CustomerEntitlements.IntegrationsSinglewire]}
          fallback={
            <Button type='primary' onClick={onSinglewireContactClick}>
              Contact for Pricing
            </Button>
          }>
          {hasSinglewireConfig ? (
            <Button type='primary' onClick={onSinglewireManageClick}>
              Manage Configuration
            </Button>
          ) : (
            <Button type='primary' onClick={onSinglewireConfigureClick}>
              Connect to Singlewire
            </Button>
          )}
        </PermissionGate>
      </Card>
      <ConnectionModal
        modalState={{
          open: connectionModalOpen,
          handleClose: () => setConnectionModalOpen(false),
          handleOpen: () => false,
        }}
      />
    </>
  );
};
