import { useState } from 'react';
import { InputRow, styledLinkClass } from '../../Base';
import { Grid, Typography, useTheme } from '@mui/material';
import {
  INTERNAL_SINGLEWIRE_API_URL,
  useAddSinglewireConfigMutation,
  useAddWebhookMutation,
  useUserQuery,
  useVerifySinglewireConnectionMutation,
} from '../../../Services/API';
import { LoadingButton, Button, Modal, TextField, ModalState } from '@perry-weather/component-library';
import { useDispatch } from 'react-redux';
import { openToast } from '../../../features/toast/toastSlice';
import { useLocation } from 'wouter';

type ConnectionModalProps = {
  modalState: ModalState;
};

type SinglewireConfig = {
  apiToken: string;
};

const VERSION_NUMBER = '1.0';

export const ConnectionModal = (props: ConnectionModalProps) => {
  const { modalState } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const setLocation = useLocation()[1];

  const { data: user } = useUserQuery();
  const linkClass = styledLinkClass(theme);
  const [editedConfig, setEditedConfig] = useState<SinglewireConfig>({ apiToken: '' });
  const formComplete = editedConfig.apiToken !== '';

  const [verifySinglewireConnection] = useVerifySinglewireConnectionMutation();
  const [addSinglewireConfigMutation] = useAddSinglewireConfigMutation();
  const [createSinglewireWebhookMutation] = useAddWebhookMutation();
  const [isLoading, setIsLoading] = useState(false);

  const onSaveClick = async () => {
    setIsLoading(true);
    try {
      // First verify the API token is valid
      const verifyResponse = await verifySinglewireConnection(editedConfig.apiToken);
      if (verifyResponse.hasOwnProperty('error') || verifyResponse.data === false) {
        dispatch(
          openToast({
            variant: 'error',
            header: 'Unable to verify Singlewire credentials',
            message: 'Verify your API token and try again',
            autoHideDuration: 6000,
          })
        );
        return;
      }

      // Then create a webhook for the customer
      const webhookResponse = await createSinglewireWebhookMutation({
        url: `${INTERNAL_SINGLEWIRE_API_URL}/webhook/policy-event/customer/${user!.customerId}`,
        customerId: user!.customerId,
        versionNumber: VERSION_NUMBER,
        readonly: true,
        name: 'Singlewire Integration Webhook',
        description: 'Webhook for Singlewire Integration',
      });
      if (webhookResponse.hasOwnProperty('error')) {
        dispatch(
          openToast({
            variant: 'error',
            header: 'Unable to configure Singlewire integration',
            message: 'Please try again later',
          })
        );
        return;
      }

      // Finally, save the configuration for the webhook
      const configResponse = await addSinglewireConfigMutation({
        customerId: user!.customerId,
        webhookId: webhookResponse.data.id!,
        token: editedConfig.apiToken,
      });
      if (configResponse.hasOwnProperty('error')) {
        dispatch(
          openToast({
            variant: 'error',
            header: 'Unable to configure Singlewire integration',
            message: 'Please try again later',
          })
        );
        return;
      }

      dispatch(openToast({ variant: 'success', header: 'Singlewire configuration saved' }));
      setLocation('/Integrations/Singlewire');
      modalState.handleClose();
    } catch (e) {
      console.error(e);
      dispatch(
        openToast({
          variant: 'error',
          header: 'Unable to verify Singlewire credentials',
          message: 'Verify your API token and try again',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal modalState={modalState}>
      <Grid container>
        <Grid item xs={12} marginBottom='8px'>
          <Typography variant='h5'>Configure Singlewire</Typography>
        </Grid>
        <Grid item xs={12} direction='row' display='flex' marginBottom={4}>
          <Typography variant='body1' color='textSecondary'>
            Click{' '}
            <a
              href='https://www.singlewire.com/informacast'
              className={linkClass.link}
              rel='noreferrer'
              target='_blank'>
              here
            </a>
            &nbsp;to learn how to configure InformaCast for the Perry Weather policy alerts integration, then enter your
            Service Account token here to connect.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputRow label='API Token'>
            <TextField
              value={editedConfig.apiToken}
              onChange={e =>
                setEditedConfig({
                  ...editedConfig,
                  apiToken: e.target.value,
                })
              }
              placeholder='Service Account Token'
            />
          </InputRow>
        </Grid>
        <Grid container display='flex' justifyContent='flex-end' spacing={1}>
          <Grid item>
            <Button onClick={() => modalState.handleClose()}>Cancel</Button>
          </Grid>
          <Grid item>
            <LoadingButton
              disabled={!formComplete}
              onClick={() => onSaveClick()}
              loadingText='Saving'
              isLoading={isLoading}
              finishedLoading={() => {}}>
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
