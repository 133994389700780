import { Select as MUISelect, SelectChangeEvent, Theme, Typography } from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { useState } from 'react';
import { PWMenuItem } from '../../Menu/MenuItem';

export interface SelectOption {
    value: string | number;
    text: string;
    bold?: boolean;
    italic?: boolean;
}

interface SelectProps {
    value?: string | number;
    disabled?: boolean;
    options: SelectOption[];
    placeholder?: string;
    isError?: boolean;
    onChange?: (value: string, name: string) => void;
    renderMenuItem?: (option: SelectOption, selectedValue?: string | number) => JSX.Element;
}

const useStyles = (theme: Theme): { [key: string]: SxProps } => ({
    select: {
        width: '100%',
        height: 48,
        '& .MuiOutlinedInput-root': {
            height: 'inherit',
            '& .MuiInputAdornment-root .MuiIconButton-root': {
                color: theme.palette.action.inactive
            }
        },
        '& .MuiOutlinedInput-adornedEnd ': {
            padding: 0
        },
        '& p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        '& .MuiSvgIcon-root': {
            padding: 0,
            color: theme.palette.text.primary
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            // background: theme.palette.background.focus,
            borderWidth: '1px !important',
            boxShadow: '0px 0px 0px 2px #0F151E, 0px 0px 0px 4px rgba(96, 177, 215, 0.3)'
        },
        '& .MuiOutlinedInput-root.Mui-error': {
            '& .MuiInputAdornment-root': {
                color: theme.palette.error.light
            }
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px 32px 12px 14px',
            zIndex: 1
        },
        '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: '1px solid',
            borderColor: theme.palette.action.disabled
        },
        '&:hover .Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: '1px solid',
            borderColor: theme.palette.action.disabled
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.border.input,
            borderRadius: 100
        },
        '&:hover .Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid',
            borderColor: theme.palette.action.focus
        },
        '& .Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.light
        }
    },
    menuItem: {
        '&:hover': {
            backgroundColor: theme.palette.action.hoverSecondary
        }
    }
});

export function Select(props: SelectProps) {
    const theme = useTheme();
    const { value, options, disabled, placeholder, onChange, renderMenuItem, isError = false } = props;
    const classes = useStyles(theme);
    const [selectValue, setSelection] = useState<string>('');

    const MenuProps = {
        PaperProps: {
            style: {
                borderRadius: 8,
                background: theme.palette.background.default,
                border: '1px solid',
                borderColor: theme.palette.border.main
            }
        }
    };

    const handleSelect = (event: SelectChangeEvent) => {
        setSelection(event.target.value);
        if (onChange !== undefined) onChange(event.target.value, options.find(x => x.value === event.target.value)?.text as string);
    };

    useEffect(() => {
        if (value) {
            setSelection(value as string);
        } else if (typeof value === 'number') {
            setSelection(value.toString());
        }
    }, [value]);

    const selectClasses = { ...classes.select };
    const menuItemClasses = { ...classes.menuItem };

    return (
        <MUISelect
            value={selectValue}
            disabled={disabled || false}
            error={isError}
            variant="outlined"
            placeholder={placeholder}
            onChange={handleSelect}
            displayEmpty
            sx={selectClasses}
            MenuProps={MenuProps}
            renderValue={
                selectValue || selectValue.length > 0
                    ? () => <Typography>{options.find(o => o.value === selectValue)?.text}</Typography>
                    : () => <Typography color={theme.palette.action.inactive}>{placeholder}</Typography>
            }>
            {options.map(
                renderMenuItem
                    ? option => {
                          return renderMenuItem(option, value);
                      }
                    : option => {
                          return (
                              <PWMenuItem
                                  key={option.value}
                                  value={option.value}
                                  sx={{
                                      ...menuItemClasses,
                                      '&:hover': {
                                          backgroundColor: theme.palette.action.hoverSecondary
                                      }
                                  }}>
                                  {option.value === '' ? (
                                      <div style={{ fontStyle: 'italic' }}>{option.text || option.value}</div>
                                  ) : (
                                      <div
                                          style={{
                                              fontStyle: option.italic ? 'italic' : 'normal',
                                              fontWeight: option.bold ? 'bold' : 'normal'
                                          }}>
                                          {option.text || option.value}
                                      </div>
                                  )}
                              </PWMenuItem>
                          );
                      }
            )}
        </MUISelect>
    );
}
