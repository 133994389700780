import { Theme } from '@mui/material';
import { Link as WouterLink } from 'wouter';
import { Link as BaseLink } from '@perry-weather/component-library';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ComponentProps } from 'react';

export const styledLinkClass = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      /* unvisited link */
      '&:link': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },

      /* visited link */
      '&:visited': {
        color: theme.palette.primary.main,
      },

      /* mouse over link */
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
      },
    },
  })
);

export const subtleLinkClass = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      /* unvisited link */
      '&:link': {
        color: theme.palette.text.secondary,
        textDecoration: 'underline',
      },

      /* visited link */
      '&:visited': {
        color: theme.palette.text.secondary,
        textDecoration: 'underline',
      },

      /* mouse over link */
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

type BaseLinkProps = ComponentProps<typeof BaseLink>;

export function Link(props: BaseLinkProps) {
  const { children, onClick, href, inactive = false } = props;

  const RenderedLink = (
    <BaseLink underline='hover' onClick={onClick} inactive={inactive}>
      {children}
    </BaseLink>
  );

  return href ? <WouterLink to={href}>{RenderedLink}</WouterLink> : RenderedLink;
}

export default Link;
