import React from 'react';
import { Page } from '@perry-weather/component-library';
import { Grid, Typography } from '@mui/material';
import Map from '../Components/Dashboard/Map/Map';
import { useHardwareQuery } from '../Services/API';
import { Marker } from 'react-map-gl';
import { CellularNetworkIcon } from '../Assets/CellularNetwork';

export function Ian() {
  const { data } = useHardwareQuery();
  // console.log(data);

  const hardwares = (
    <>
      {data?.map(hardware => {
        return (
          <>
            <Marker
              key={hardware.id}
              offsetLeft={-20}
              offsetTop={-30}
              latitude={hardware.latitude}
              longitude={hardware.longitude}>
              <CellularNetworkIcon color='black' />
            </Marker>
            {/* <Circle index={1} latitude={siren.latitude} longitude={siren.longitude} radius={siren.radius} color={theme.palette.error.light}/> */}
          </>
        );
      })}
    </>
  );

  return (
    <Page
      docTitle="Ian's Page"
      title={
        <Typography variant='h3' color='textPrimary' noWrap>
          Ian's Page
        </Typography>
      }>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Map>{hardwares}</Map>
        </Grid>
      </Grid>
    </Page>
  );
}

export default Ian;
