import { AppBar, Avatar, Divider, useScrollTrigger } from '@mui/material';
import React, { useContext } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Box, Button, IconButton, Toolbar } from '@mui/material';
import { Link } from 'wouter';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import { useAuth } from 'oidc-react';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppDispatch } from '../../../app/hooks';
import { toggle } from '../../../features/menu/menuSlice';
import { Menu as PWMenu, PWMenuItem } from '@perry-weather/component-library';
import { PWColors } from '../../../Theme/PWColors';
import { useNotificationSettingsQuery } from '../../../Services/API';
import ForwardArrow from '../../../Assets/NotificationIcons/ForwardArrow';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import UserAlerts from './UserAlerts';
import { PWThemeContext, ThemeType } from '@perry-weather/component-library';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    title: {
      flexGrow: 1,
      justifyItems: 'space-around',
      justifyContent: 'space-around',
    },
    titleBar: {
      color: theme.palette.text.primary,
      justifyItems: 'space-around',
      justifyContent: 'space-around',
    },
    appbar: {},
    avatar: {
      background:
        'linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3)',
      backgroundSize: '1800% 1800%',
      animation: `$rainbow 18s ease infinite`,
    },
    '@keyframes rainbow': {
      '0%': {
        backgroundPosition: '0% 90%',
      },
      '50%': {
        backgroundPosition: '100% 19%',
      },
      '100%': {
        backgroundPosition: '0% 82%',
      },
    },
  })
);

export default function MainMenu() {
  const { theme: themeType, setTheme } = useContext(PWThemeContext);
  const theme = useTheme();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notiAnchorEl, setNotiAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const notiOpen = Boolean(notiAnchorEl);
  const dispatch = useAppDispatch();
  /// Annoyingly calling signoutRedirect from oidc-react does not work.
  /// So we have to call the signoutRedirect directly from usermanager,
  /// This means it doesn't trigger onSignout() from oidc-react
  const { userData, userManager } = useAuth();
  const { data: notiSettings } = useNotificationSettingsQuery();

  const handleThemeChange = () => {
    if (themeType === ThemeType.DARK) setTheme(ThemeType.LIGHT);
    else setTheme(ThemeType.DARK);
  };

  // const handleChange = (event: any) => {
  // setAuth(event.target.checked);
  // };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotiMenu = (event: any) => {
    setNotiAnchorEl(event.currentTarget);
  };

  const handleNotiClose = () => {
    setNotiAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
  });

  var initials =
    (userData?.profile.given_name || '').substring(0, 1) + (userData?.profile.family_name || '').substring(0, 1);

  const NotificationStateIcon = () => {
    if (notiSettings?.declinesNotifications)
      return <NotificationsOffOutlinedIcon htmlColor={theme.palette.error.main} />;
    else return <NotificationsNoneIcon />;
  };

  return (
    <>
      <AppBar
        position='fixed'
        elevation={trigger ? 24 : 0}
        enableColorOnDark={true}
        style={{
          backgroundColor: trigger ? theme.palette.background.default : 'transparent',
          boxShadow: trigger ? '5px 0px 27px -5px rgba(0, 0, 0, 0.3) !important' : undefined,
        }}>
        <Toolbar style={{ backgroundColor: trigger ? theme.palette.background.default : 'transparent' }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={() => dispatch(toggle())}
            className={classes.menuButton}
            size='large'>
            <MenuIcon />
          </IconButton>

          <div className={classes.title} />
          <div className={classes.titleBar}>
            <IconButton
              href='https://support.perryweather.com/hc'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='false'
              color='inherit'
              size='large'>
              <HelpOutlineIcon />
            </IconButton>
            <IconButton
              onClick={handleNotiMenu}
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='false'
              color='inherit'
              size='large'>
              {NotificationStateIcon()}
            </IconButton>
            {notiSettings?.declinesNotifications && (
              <Link to='/NotificationSettings' style={{ borderRadius: 100 }}>
                <IconButton
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='false'
                  color='inherit'
                  size='large'>
                  <Typography variant='subtitle1' textAlign='left' color={theme.palette.error.main}>
                    Notifications Disabled
                  </Typography>
                </IconButton>
              </Link>
            )}
            <PWMenu open={notiOpen} anchorEl={notiAnchorEl} onClose={handleNotiClose}>
              <Typography margin='16px' variant='subtitle1' textAlign='left'>
                Notifications
              </Typography>
              <UserAlerts />
              <PWMenuItem>
                <Box display='flex' justifyContent='center' alignItems='center' width='100%' gap={1}>
                  <Link
                    to='/Notifications'
                    style={{
                      textDecoration: 'none',
                      color: PWColors.dark.primary['500'],
                      textAlign: 'center',
                      alignSelf: 'center',
                    }}
                    onClick={handleNotiClose}>
                    View All
                  </Link>
                  <ForwardArrow color={theme.palette.primary.main} />
                </Box>
              </PWMenuItem>
            </PWMenu>
            <Button
              sx={{ textTransform: 'none' }}
              color='inherit'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Avatar sx={{ width: 32, height: 32 }} className={classes.avatar}>
                  {initials}
                </Avatar>
                <Typography sx={{ marginLeft: '10px' }} variant='subtitle1'>
                  {userData?.profile.name}
                </Typography>
              </Box>
            </Button>
            <PWMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <Link
                to='/Profile'
                onClick={handleClose}
                style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                <PWMenuItem>My Profile</PWMenuItem>
              </Link>
              <Link
                to='/NotificationSettings'
                onClick={handleClose}
                style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                <PWMenuItem>Notification Settings</PWMenuItem>
              </Link>
              <PWMenuItem onClick={handleThemeChange}>
                <Box width='100%' display='flex' justifyContent='space-between'>
                  <Typography style={{ textDecoration: 'none', color: theme.palette.text.primary }}>Theme: </Typography>
                  <Typography variant='subtitle1'>{theme.palette.mode === 'dark' ? 'Dark' : 'Light'}</Typography>
                  {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
                </Box>
              </PWMenuItem>
              <Divider />
              <PWMenuItem onClick={() => userManager.signoutRedirect()}>Sign Out</PWMenuItem>
            </PWMenu>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
