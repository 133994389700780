import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ConsultationIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ExtensionOutlined from '@mui/icons-material/ExtensionOutlined';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import TeamIcon from '@mui/icons-material/GroupOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Link, useLocation } from 'wouter';
import { selectMenu, toggle } from '../../features/menu/menuSlice';
import { useAppSelector } from '../../app/hooks';
import { PWLogo } from '../../Assets/PWLogo';
import { FactCheck as PolicyIcon, Send, VideoCameraBackOutlined } from '@mui/icons-material';
import { Typography, Theme, useMediaQuery, useTheme } from '@mui/material';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';

import './Sidebar.css';
import { useDispatch } from 'react-redux';
import { CellularNetworkIcon } from '../../Assets/CellularNetwork';
import { Tooltip } from '@perry-weather/component-library';
import { AirQuality } from '../../Assets';
import PermissionGate from './PermissionGate';
import { CustomerEntitlements, UserRoles } from '../../Constants/Constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      width: 54,
      background: theme.palette.background.default,
      borderRight: 'solid 1px',
      borderRightColor: theme.palette.border.sidebar,
    },
    mobileDrawerPaper: {
      width: 260,
      background: theme.palette.background.default,
      borderRight: 'solid 1px',
      borderRightColor: theme.palette.border.sidebar,
    },
    listItem: {
      background: 'transparent',
      '& .MuiListItemIcon-root': {
        minWidth: '100%',
        justifyContent: 'center',
        color: theme.palette.action.inactive,
        alignItems: 'center',
        height: 24,
        '& .svg-container': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: theme.palette.action.inactive,
          position: 'absolute',
          borderRadius: 8,
          width: 32,
          height: 32,
        },
        '&:hover': {
          color: theme.palette.action.hover,
          '& path': {
            fill: theme.palette.action.hover,
          },
          '& .svg-container': {
            background: theme.palette.action.hoverSecondary,
          },
        },
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        '& .MuiListItemIcon-root': {
          color: theme.palette.action.selected,
          borderLeftColor: theme.palette.action.selected,
          borderLeftWidth: 2,
          borderLeftStyle: 'solid',
          '& path': {
            fill: theme.palette.action.selected,
          },
          '&:hover': {
            color: theme.palette.action.selected,
            '& path': {
              fill: theme.palette.action.selected,
            },
          },
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '&:hover': {
        background: 'transparent',
      },
    },
    mobileListItem: {
      background: 'transparent',
      '& .MuiListItemIcon-root': {
        minWidth: '100%',
        justifyContent: 'flex-start',
        color: theme.palette.action.inactive,
        alignItems: 'center',
        width: '100%',
        height: 32,
        '& .svg-container': {
          display: 'flex',
          marginLeft: 8,
          alignItems: 'center',
          borderRadius: 8,
          '& .MuiTypography-root': {
            marginLeft: 4,
          },
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        '& .MuiListItemIcon-root': {
          color: theme.palette.action.selected,
          borderLeftColor: theme.palette.action.selected,
          borderLeftWidth: 2,
          borderLeftStyle: 'solid',
          '& path': {
            fill: theme.palette.action.selected,
          },
          '&:hover': {
            color: theme.palette.action.selected,
            '& path': {
              fill: theme.palette.action.selected,
            },
          },
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '&:hover': {
        background: 'transparent',
      },
    },
  })
);

const useTooltipStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.background.disabled,
  },
  tooltip: {
    backgroundColor: theme.palette.background.disabled,
  },
}));

export default function Sidebar() {
  const theme = useTheme();

  const styles = useStyles(theme);
  const tooltipStyles = useTooltipStyles(theme);

  const hidden = useMediaQuery(theme.breakpoints.up('sm'));

  const mobileOpen = useAppSelector(selectMenu);
  const dispatch = useDispatch();
  const [location] = useLocation();

  const { SuperAdmin, Admin, AssistantAdmin } = UserRoles;

  const desktopDrawer = () => (
    <Drawer
      variant='permanent'
      classes={{
        paper: styles.drawerPaper,
      }}
      open>
      <List>
        <ListItem classes={{ root: styles.listItem }} disableGutters={true} button disableRipple>
          <Link to='/'>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <PWLogo color={theme.palette.background.default} />
            </div>
          </Link>
        </ListItem>
        <ListItem
          className={styles.listItem}
          disableGutters={true}
          selected={location.toLocaleLowerCase() === '/'}
          button
          disableRipple>
          <Link to='/'>
            <ListItemIcon>
              <Tooltip
                classes={tooltipStyles}
                title={<Typography variant='body1'>Home</Typography>}
                arrow
                placement='right'
                enterDelay={300}
                leaveDelay={200}>
                <div className='svg-container'>
                  <HomeIcon />
                </div>
              </Tooltip>
            </ListItemIcon>
          </Link>
        </ListItem>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <ListItem
            className={styles.listItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/hardware'}
            button
            disableRipple>
            <Link to='/Hardware'>
              <ListItemIcon>
                <Tooltip
                  classes={tooltipStyles}
                  title={<Typography variant='body1'>On-Site Hardware</Typography>}
                  arrow
                  placement='right'
                  enterDelay={300}
                  leaveDelay={200}>
                  <div className='svg-container'>
                    <CellularNetworkIcon width={24} height={24} color={theme.palette.action.inactive} />
                  </div>
                </Tooltip>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.AirQualityIndex]}>
          <ListItem
            className={styles.listItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/airquality'}
            button
            disableRipple>
            <Link to='/AirQuality' onClick={() => dispatch(toggle())}>
              <ListItemIcon>
                <Tooltip
                  classes={tooltipStyles}
                  title={<Typography variant='body1'>Air Quality Index</Typography>}
                  arrow
                  placement='right'
                  enterDelay={300}
                  leaveDelay={200}>
                  <div className='svg-container'>
                    <AirQuality height={24} width={24} color={theme.palette.action.inactive} />
                  </div>
                </Tooltip>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.MetSupport]}>
          <ListItem
            className={styles.listItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/consultation'}
            button
            disableRipple>
            <Link to='/Consultation'>
              <ListItemIcon>
                <Tooltip
                  classes={tooltipStyles}
                  title={<Typography variant='body1'>Ask a Meteorologist</Typography>}
                  arrow
                  placement='right'
                  enterDelay={300}
                  leaveDelay={200}>
                  <div className='svg-container'>
                    <ConsultationIcon />
                  </div>
                </Tooltip>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <ListItem
            className={styles.listItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/users'}
            button
            disableRipple>
            <Link to='/Users'>
              <ListItemIcon>
                <Tooltip
                  classes={tooltipStyles}
                  title={<Typography variant='body1'>Users</Typography>}
                  arrow
                  placement='right'
                  enterDelay={300}
                  leaveDelay={200}>
                  <div className='svg-container'>
                    <TeamIcon />
                  </div>
                </Tooltip>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <ListItem
            className={styles.listItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/policies'}
            button
            disableRipple>
            <Link to='/Policies'>
              <ListItemIcon>
                <Tooltip
                  classes={tooltipStyles}
                  title={<Typography variant='body1'>Policies</Typography>}
                  arrow
                  placement='right'
                  enterDelay={300}
                  leaveDelay={200}>
                  <div className='svg-container'>
                    <PolicyIcon />
                  </div>
                </Tooltip>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.MassNotify]}>
          <ListItem
            className={styles.listItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/massnotify'}
            button
            disableRipple>
            <Link to='/MassNotify'>
              <ListItemIcon>
                <Tooltip
                  classes={tooltipStyles}
                  title={<Typography variant='body1'>Notify Users</Typography>}
                  arrow
                  placement='right'
                  enterDelay={300}
                  leaveDelay={200}>
                  <div className='svg-container'>
                    <Send />
                  </div>
                </Tooltip>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.WidgetAccess]}>
          <ListItem
            className={styles.listItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/widgets'}
            button
            disableRipple>
            <Link to='/Widgets'>
              <ListItemIcon>
                <Tooltip
                  classes={tooltipStyles}
                  title={<Typography variant='body1'>Widgets</Typography>}
                  arrow
                  placement='right'
                  enterDelay={300}
                  leaveDelay={200}>
                  <div className='svg-container'>
                    <WidgetsOutlinedIcon />
                  </div>
                </Tooltip>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.LiveCamera]}>
          <ListItem
            className={styles.listItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/livecamera'}
            button
            disableRipple>
            <Link to='/LiveCamera'>
              <ListItemIcon>
                <Tooltip
                  classes={tooltipStyles}
                  title={<Typography variant='body1'>Live Camera</Typography>}
                  arrow
                  placement='right'
                  enterDelay={300}
                  leaveDelay={200}>
                  <div className='svg-container'>
                    <VideoCameraBackOutlined />
                  </div>
                </Tooltip>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.IntegrationsSinglewire]}>
          <ListItem
            className={styles.listItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/integrations'}
            button
            disableRipple>
            <Link to='/Integrations'>
              <ListItemIcon>
                <Tooltip
                  classes={tooltipStyles}
                  title={<Typography variant='body1'>Integrations</Typography>}
                  arrow
                  placement='right'
                  enterDelay={300}
                  leaveDelay={200}>
                  <div className='svg-container'>
                    <ExtensionOutlined />
                  </div>
                </Tooltip>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.BroadcastViewAccess]}>
          <ListItem
            className={styles.listItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/broadcast'}
            button
            disableRipple>
            <Link to='/Broadcast'>
              <ListItemIcon>
                <Tooltip
                  classes={tooltipStyles}
                  title={<Typography variant='body1'>Broadcast</Typography>}
                  arrow
                  placement='right'
                  enterDelay={300}
                  leaveDelay={200}>
                  <div className='svg-container'>
                    <PodcastsIcon />
                  </div>
                </Tooltip>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
      </List>
    </Drawer>
  );

  const mobileDrawer = () => (
    <Drawer
      variant='temporary'
      open={mobileOpen}
      onClose={() => {
        dispatch(toggle());
      }}
      classes={{
        paper: styles.mobileDrawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}>
      <List>
        <ListItem classes={{ root: styles.mobileListItem }} disableGutters={true} button disableRipple>
          <Link to='/' onClick={() => dispatch(toggle())}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <PWLogo color={theme.palette.background.default} />
            </div>
          </Link>
        </ListItem>
        <ListItem
          className={styles.mobileListItem}
          disableGutters={true}
          selected={location.toLocaleLowerCase() === '/'}
          button
          disableRipple>
          <Link to='/' onClick={() => dispatch(toggle())}>
            <ListItemIcon>
              <div className='svg-container'>
                <HomeIcon fontSize='large' />
                <Typography fontSize='22px'>Home</Typography>
              </div>
            </ListItemIcon>
          </Link>
        </ListItem>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <ListItem
            className={styles.mobileListItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/hardware'}
            button
            disableRipple>
            <Link to='/Hardware' onClick={() => dispatch(toggle())}>
              <ListItemIcon>
                <div className='svg-container'>
                  <CellularNetworkIcon height={36} width={36} color={theme.palette.action.inactive} />
                  <Typography fontSize='22px'>On-Site Hardware</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.AirQualityIndex]}>
          <ListItem
            className={styles.mobileListItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/airquality'}
            button
            disableRipple>
            <Link to='/AirQuality' onClick={() => dispatch(toggle())}>
              <ListItemIcon>
                <div className='svg-container'>
                  <AirQuality height={36} width={36} color={theme.palette.action.inactive} />
                  <Typography fontSize='22px'>Air Quality Index</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.MetSupport]}>
          <ListItem
            className={styles.mobileListItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/consultation'}
            button
            disableRipple>
            <Link to='/Consultation' onClick={() => dispatch(toggle())}>
              <ListItemIcon>
                <div className='svg-container'>
                  <ConsultationIcon fontSize='large' />
                  <Typography fontSize='22px'>Ask A Meteorologist</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <ListItem
            className={styles.mobileListItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/users'}
            button
            disableRipple>
            <Link to='/Users' onClick={() => dispatch(toggle())}>
              <ListItemIcon>
                <div className='svg-container'>
                  <TeamIcon fontSize='large' />
                  <Typography fontSize='22px'>Users</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <ListItem
            className={styles.mobileListItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/policies'}
            button
            disableRipple>
            <Link to='/Policies' onClick={() => dispatch(toggle())}>
              <ListItemIcon>
                <div className='svg-container'>
                  <PolicyIcon fontSize='large' />
                  <Typography fontSize='22px'>Policies</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.MassNotify]}>
          <ListItem
            className={styles.mobileListItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/massnotify'}
            button
            disableRipple>
            <Link to='/MassNotify' onClick={() => dispatch(toggle())}>
              <ListItemIcon>
                <div className='svg-container'>
                  <Send fontSize='large' />
                  <Typography fontSize='22px'>Notify Users</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.WidgetAccess]}>
          <ListItem
            className={styles.mobileListItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/widgets'}
            button
            disableRipple>
            <Link to='/Widgets' onClick={() => dispatch(toggle())}>
              <ListItemIcon>
                <div className='svg-container'>
                  <WidgetsOutlinedIcon fontSize='large' />
                  <Typography fontSize='22px'>Widgets</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.LiveCamera]}>
          <ListItem
            className={styles.mobileListItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/livecamera'}
            button
            disableRipple>
            <Link to='/LiveCamera' onClick={() => dispatch(toggle())}>
              <ListItemIcon>
                <div className='svg-container'>
                  <VideoCameraBackOutlined fontSize='large' />
                  <Typography fontSize='22px'>Live Camera</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]} allowedEntitlements={[]}>
          {' '}
          {/* TODO: remove this when the feature is ready to launch, as integrations are not tied to an entitlement */}
          <ListItem
            className={styles.mobileListItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/integrations'}
            button
            disableRipple>
            <Link to='/Integrations' onClick={() => dispatch(toggle())}>
              <ListItemIcon>
                <div className='svg-container'>
                  <ExtensionOutlined fontSize='large' />
                  <Typography fontSize='22px'>Integrations</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.BroadcastViewAccess]}>
          {' '}
          {/* TODO: remove this when the feature is ready to launch, as integrations are not tied to an entitlement */}
          <ListItem
            className={styles.mobileListItem}
            disableGutters={true}
            selected={location.toLocaleLowerCase() === '/broadcast'}
            button
            disableRipple>
            <Link to='/Broadcast' onClick={() => dispatch(toggle())}>
              <ListItemIcon>
                <div className='svg-container'>
                  <PodcastsIcon fontSize='large' />
                  <Typography fontSize='22px'>Broadcast</Typography>
                </div>
              </ListItemIcon>
            </Link>
          </ListItem>
        </PermissionGate>
      </List>
    </Drawer>
  );

  return <Container>{hidden ? desktopDrawer() : mobileDrawer()}</Container>;
}
