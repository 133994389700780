import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ToastProps } from '@perry-weather/component-library';

export type ToastState = Pick<ToastProps, 'show' | 'variant' | 'header' | 'message' | 'autoHideDuration'>;
export type ToastContent = Pick<ToastProps, 'variant' | 'header' | 'message' | 'autoHideDuration'>;

const initialState: ToastState = {
  show: false,
  variant: 'success',
  header: '',
  message: undefined,
  autoHideDuration: undefined,
};

const MIN_DISPLAY_TIME = 3000;

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    closeToast: state => {
      state.show = false;
    },
    openToast: (state, actions: PayloadAction<ToastContent>) => {
      state.variant = actions.payload.variant;
      state.header = actions.payload.header;
      state.message = actions.payload.message;
      state.show = true;
      state.autoHideDuration = actions.payload.autoHideDuration;
      if (!actions.payload.autoHideDuration) {
        state.autoHideDuration = Math.max(actions.payload.header.length * 80, MIN_DISPLAY_TIME);
      }
    },
  },
});

export const { closeToast, openToast } = toastSlice.actions;

export const selectToast = (state: RootState) => state.toast;

export default toastSlice.reducer;
