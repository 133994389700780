import { memo } from 'react';
import { Snackbar, SnackbarCloseReason } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Bread, BreadVariant } from './Bread';
import { Check, ErrorOutline, InfoOutlined, WarningAmber } from '@mui/icons-material';

export interface ToastProps {
    variant: BreadVariant;
    header: string;
    message?: string;
    show: boolean;
    autoHideDuration?: number;
    disableAutoHide?: boolean;
    onToastClose?: (event: Event | React.SyntheticEvent<unknown, Event>, reason: SnackbarCloseReason) => void;
    onClose?: () => void;
}

export const Toast = memo(function Snack(props: ToastProps) {
    const theme = useTheme();
    const { variant, header, message, show, onToastClose, onClose, disableAutoHide = false, autoHideDuration = 3000 } = props;

    const iconElem = () => {
        switch (variant) {
            case 'success':
                return <Check />;
            case 'error':
                return <ErrorOutline />;
            case 'warn':
                return <WarningAmber />;
            case 'info':
                return <InfoOutlined />;
            default:
                return undefined;
        }
    };

    return (
        <Snackbar
            autoHideDuration={disableAutoHide ? null : autoHideDuration}
            transitionDuration={500}
            onClose={onToastClose}
            open={show}
            style={{
                position: 'fixed',
                top: '80px',
                right: '2%',
                bottom: 'auto',
                left: 'auto',
                minWidth: '10%',
                [theme.breakpoints.up('sm')]: {
                    maxWidth: '20%'
                }
            }}>
            <div>
                <Bread variant={variant} header={header} icon={iconElem()} message={message} closeable onClose={onClose} />
            </div>
        </Snackbar>
    );
});
