import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { OrganizationLocation, useCustomerOrgLocationsQuery } from '../../Services/API';
import { MultiSelect, SelectOption, PWMenuItem, Checkbox } from '@perry-weather/component-library';

interface LocationSelectProps {
  customerId: number;
  values?: string[];
  placeholder?: string;
  onChange: (locations: OrganizationLocation[]) => void;
}

const CustomMenuItem = (option: SelectOption, selectValues: any) => {
  let isSelected = selectValues.includes(option.value);

  return (
    <PWMenuItem key={option.value} value={option.value}>
      <Checkbox checked={isSelected} onChange={() => {}} />
      {option.text || option.value}
    </PWMenuItem>
  );
};

export function LocationSelect(props: LocationSelectProps) {
  const { customerId, values, onChange, placeholder } = props;

  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);
  const { data: userOrgLocations, isSuccess: isUserOrgLocationsSuccess } = useCustomerOrgLocationsQuery(customerId);

  const handleChange = (locationIds: string[]) => {
    if (onChange && userOrgLocations) onChange(userOrgLocations.filter(x => locationIds.some(y => y === x.id)));
    setSelectedLocations(locationIds);
  };

  useEffect(() => {
    if (values && values.length > 0) {
      setSelectedLocations(values);
    }
  }, [values]);

  useEffect(() => {
    if (userOrgLocations && isUserOrgLocationsSuccess)
      setLocationOptions(userOrgLocations.map(loc => ({ value: loc.id, text: loc.name })));
  }, [userOrgLocations, isUserOrgLocationsSuccess]);

  return (
    <MultiSelect
      placeholder={placeholder || 'Select location(s)...'}
      renderMenuItem={CustomMenuItem}
      renderValue={
        <Typography>{`${selectedLocations.length} location${
          selectedLocations.length > 1 ? 's' : ''
        } selected`}</Typography>
      }
      value={selectedLocations}
      options={locationOptions}
      onChange={handleChange}
    />
  );
}
