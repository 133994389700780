import { FormControlLabel, Radio as MuiRadio, styled, useTheme } from '@mui/material';

const CustomRadio = styled(MuiRadio)``;

interface RadioProps {
    value: string;
    size?: 'small' | 'medium';
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    checked?: boolean;
    label?: string;
}

export function Radio(props: RadioProps) {
    const { onChange, size, checked, value, label } = props;
    const theme = useTheme();

    const BaseRadio = (
        <CustomRadio
            onChange={onChange}
            checked={checked}
            value={value}
            size={size}
            sx={{
                color: theme.palette.border.input,
                '&:hover': {
                    color: theme.palette.action.hover
                }
            }}></CustomRadio>
    );

    if (label) {
        return <FormControlLabel control={BaseRadio} label={label} />;
    }
    return BaseRadio;
}

export default Radio;
