export const PWColors = {
    dark: {
        base: {
            900: '#121A24',
            800: '#17202C',
            700: '#1F2936',
            600: '#293442',
            500: '#35404F',
            400: '#414C5B',
            300: '#4F5A68',
            200: '#626B77',
            100: '#767F8A'
        },
        primary: {
            900: '#1F4676',
            800: '#2F628F',
            700: '#4687B3',
            650: '#5AE1FF',
            600: '#60B1D7',
            500: '#7DDEFB',
            400: '#9CEEFD',
            300: '#B0F9FD',
            200: '#CAFDFB',
            100: '#E4FEFB'
        },
        danger: {
            900: '#310B08',
            800: '#882836',
            700: '#AA3A42',
            600: '#CB4F4E',
            500: '#EE7064',
            400: '#F29C8A',
            300: '#F5B6A2',
            200: '#F9D4C1',
            100: '#FCECE0'
        },
        warning: {
            900: '#29260A',
            800: '#7F842F',
            700: '#9EA43F',
            600: '#BEC451',
            500: '#DFE566',
            400: '#EAEF80',
            300: '#F4F79F',
            200: '#FAFCBF',
            100: '#FCFDDE'
        },
        success: {
            900: '#142828',
            800: '#4C8A5F',
            700: '#68AC77',
            600: '#8BCE93',
            500: '#B3F1B5',
            400: '#C5F5C2',
            300: '#D3FACD',
            200: '#E5FCDE',
            100: '#F3FEEE'
        }
    },
    light: {
        base: {
            900: '#121A24',
            800: '#28313D',
            700: '#1F2936',
            600: '#39424F',
            500: '#636C76',
            400: '#88919B',
            300: '#B4BCC6',
            200: '#DBDFE3',
            100: '#F0F3F6'
        },
        primary: {
            900: '#153360',
            800: '#204874',
            700: '#4687B3',
            600: '#306491',
            500: '#56A8CB',
            400: '#7CCADF',
            300: '#AAE1F2',
            200: '#DAF5FB',
            100: '#EDFCFD'
        },
        danger: {
            900: '#721329',
            800: '#8A1F30',
            700: '#AB323B',
            600: '#CB4F4E',
            500: '#EE7064',
            400: '#F29C8A',
            300: '#F5B6A2',
            200: '#F9D4C1',
            100: '#FCECE0'
        },
        warning: {
            900: '#676D13',
            800: '#7E8420',
            700: '#9EA433',
            600: '#BEC44A',
            500: '#DFE566',
            400: '#EBEF8B',
            300: '#F4F7A4',
            200: '#FAFCC4',
            100: '#FCFDE1'
        },
        success: {
            900: '#16643A',
            800: '#257944',
            700: '#3A9754',
            600: '#55B467',
            500: '#75D27E',
            400: '#98E398',
            300: '#B6F1B1',
            200: '#D5FACE',
            100: '#ECFCE6'
        }
    }
};
