import { useEffect, useState } from 'react';

export const useLocalStorage = <T extends string>(key: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        try {
            const currentValue = (localStorage.getItem(key) ?? defaultValue) as T;
            if (value !== currentValue) {
                setValue(currentValue);
            }
        } catch (error) {
            console.error(`Error reading ${key} from localStorage`, error);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(key, value);
    }, [value, key]);

    return [value, setValue];
};

export default useLocalStorage;
