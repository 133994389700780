import { CircularProgress, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import IframeResizer, { IFrameObject } from 'iframe-resizer-react';
import { useEffect, useRef, useState } from 'react';
import { WidgetParams } from '../../Pages/WidgetBuilder';
import { WidgetSize } from '../../Services/API/widgetApi';
import { Button, Card } from '@perry-weather/component-library';
import { ShareWidgetModal } from './ShareWidgetModal';

interface WidgetCardProps {
  widgetId?: string;
  size: WidgetSize;
  navigate: (widgetId: string, customerId: number) => void;
  isBuilderPreview?: boolean;
  widgetParams?: WidgetParams;
  name?: string;
}
interface iFrameScaling {
  scale: number;
  horizontalMargin: number;
  verticalMargin: number;
}

export function WidgetCard(props: WidgetCardProps) {
  const { widgetId, navigate, isBuilderPreview, widgetParams } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [iFrameScaling, setIFrameScaling] = useState<iFrameScaling>({
    scale: 1,
    horizontalMargin: 0,
    verticalMargin: 0,
  });
  const [iFrameDimensions, setIFrameDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const [embedWidth, setEmbedWidth] = useState<string>('100%');
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const width = widgetParams?.width;

  const updateFrameDimensions = (height: number, width: number) => {
    if (height > 0 && width > 0) {
      setLoading(false);
      setIFrameDimensions({ width: width, height: height });
    }
  };

  /** Iframe resizing is not super obivious so I'll explain what's going on here.
        We need to scale the iFrame to fit in the width avialable to the card.
        Setting the width of the iframe will change the appearance of the widget, so we need to use transform: scale(x)
        transform: scale(x) is a graphic transform only, will not affect the DOM flow.
        After scaling, we have to use a negative margin to offset the scaling.

        Example: Iframe has width of 800px, but only gets 400px of space. We scale it by 0.5 so it fits.
        Iframe will appear to only be 400px wide, but the DOM still knows iframe is 800px wide.
        Apply margin of -200px to offset scaling.
    */
  const handleResize = () => {
    let scale = 1;
    let horizontalMargin = 0;
    let verticalMargin = 0;

    if (iFrameDimensions.height > 0 && ref.current) {
      const containerWidth = ref.current.offsetWidth;
      if (containerWidth && iFrameDimensions.width > containerWidth && width != undefined) {
        scale = containerWidth / width;
        horizontalMargin = Math.abs(containerWidth - width) / 2;
        verticalMargin = (iFrameDimensions.height - iFrameDimensions.height * scale) / 2;
      }
      setIFrameScaling({ scale, horizontalMargin, verticalMargin });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [iFrameDimensions]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let baseUrl = `http://localhost:9000`;
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_WIDGET_URL === undefined) {
    baseUrl = 'http://localhost:9000';
  } else {
    baseUrl = process.env.REACT_APP_WIDGET_URL!;
  }

  //prod
  // const widgetSrc = `https://widget.perryweather.com/index.html?id=${widgetId}`;

  //staging
  // const widgetSrc = `https://main.widget-4sb.pages.dev/?id=${widgetId}`

  const widgetSrc = `${baseUrl}/?id=${widgetId}`;

  const iframeRef = useRef<IFrameObject>(null);
  useEffect(() => {
    if (iframeRef.current && widgetParams) {
      iframeRef.current.sendMessage(widgetParams, widgetSrc);
    }
  }, [widgetParams]);

  return (
    <>
      <Card header={widgetParams && widgetParams.name} headerSubtitle={widgetParams && widgetParams.customerName}>
        <Grid container flexDirection={'column'} height={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <Grid width={'100%'} ref={ref} item>
            <IframeResizer
              forwardRef={iframeRef}
              onResized={e => updateFrameDimensions(e.height, e.width)}
              src={widgetSrc}
              heightCalculationMethod='taggedElement'
              style={{
                border: 'none',
                width: `${width}px`,
                marginTop: `-${iFrameScaling.verticalMargin}px`,
                marginBottom: `-${iFrameScaling.verticalMargin}px`,
                marginLeft: `-${iFrameScaling.horizontalMargin}px`,
                marginRight: `-${iFrameScaling.horizontalMargin}px`,
                transform: `scale(${iFrameScaling.scale})`,
              }}
            />
          </Grid>
          {loading && <CircularProgress />}
          {!isBuilderPreview && (
            <Grid container flexDirection={'column'}>
              {widgetId && widgetParams && (
                <Button style={{ marginTop: '10px' }} onClick={() => navigate(widgetId, widgetParams.customerId)}>
                  Edit Widget
                </Button>
              )}
              <Button type='clearBlue' style={{ marginTop: '10px' }} onClick={e => handleOpen()}>
                Get Embed Code
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>

      {widgetId && (
        <ShareWidgetModal widgetId={widgetId} open={open} handleClose={handleClose} handleOpen={handleOpen} />
      )}
    </>
  );
}
