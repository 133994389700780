import { Grid, Typography, useTheme } from '@mui/material';
import { Card, Page } from '@perry-weather/component-library';
import { useEffect, useRef, useState } from 'react';
import {
  baseLayerType,
  getBaseLayer,
  getOverlays,
  getShowAllLocations,
  setShowAllLocations,
} from '../features/map/mapSlice';
import ReactMapGL, { MapRef, AttributionControl } from 'react-map-gl';
import { useAppSelector } from '../app/hooks';
import { LocationMarker } from '../Components/Dashboard/Map/Markers';
import { Radar } from '../Components/Dashboard/Map/Radar/Radar';
import { getSelectedLocation, selectLocation } from '../features/dash/dashSlice';
import LightningQuickLayer from '../Components/Dashboard/Map/Layers/LightningQuickLayer';
import { useDispatch } from 'react-redux';
import { useLocationsQuery } from '../Services/API';
import MasterMapLocationLayer from '../Components/Dashboard/Map/Markers/MasterMapLocationMarkers';
import MasterMapLocation from '../types/MasterMapLocation';

const mapTilerKey = process.env.REACT_APP_MAP_TILER_KEY;

interface MapProps {
  children?: React.ReactNode;
}

export function BroadcastView(props: MapProps) {
  const chosenStyle = useAppSelector(getBaseLayer);
  const chosenLayers = useAppSelector(getOverlays);
  const selectedLocation = useAppSelector(getSelectedLocation);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { data: locations } = useLocationsQuery();
  const showAllLocations = useAppSelector(getShowAllLocations);

  const [viewport, setViewport] = useState({
    latitude: 32.7767,
    longitude: -96.797,
    zoom: 8,
  });
  const mapStyle = getMapStyle(chosenStyle);

  const mapRef = useRef<MapRef>(null);

  function onVPChange(nextVP: any) {
    setViewport(nextVP);
  }

  const getInteractiveLayerIds = () => {
    let layerIds: string[] = [];
    if (chosenLayers.includes('lightning')) {
      layerIds.push('unclustered-point-green', 'unclustered-point-yellow', 'unclustered-point-red');
    }

    if (chosenLayers.includes('storm-vectors')) {
      layerIds.push('storm-vector-point');
    }

    if (chosenLayers.includes('tropical-forecast')) {
      layerIds.push('tropical-forecast-point-layer');
    }
    return layerIds;
  };

  useEffect(() => {
    if (mapRef.current) {
      let map = mapRef.current!.getMap();
      if (map) {
        // map.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/cat.png', (error: any, image: any) => {
        map.loadImage('/LightningStrike.png', (error: any, image: any) => {
          if (error) throw error;
          if (!map.hasImage('map-lightning')) map.addImage('map-lightning', image, { sdf: true });
        });
        map.loadImage('/LightningStrike-Red.png', (error: any, image: any) => {
          if (error) throw error;
          if (!map.hasImage('map-lightning-outline-red'))
            map.addImage('map-lightning-outline-red', image, { sdf: false });
        });
        map.loadImage('/LightningStrike-Yellow.png', (error: any, image: any) => {
          if (error) throw error;
          if (!map.hasImage('map-lightning-outline-yellow'))
            map.addImage('map-lightning-outline-yellow', image, { sdf: false });
        });
        map.loadImage('/LightningStrike-Green.png', (error: any, image: any) => {
          if (error) throw error;
          if (!map.hasImage('map-lightning-outline-green'))
            map.addImage('map-lightning-outline-green', image, { sdf: false });
        });
        map.loadImage('/Triangle.png', (error: any, image: any) => {
          if (error) throw error;
          if (!map.hasImage('triangle-outline')) map.addImage('triangle-outline', image, { sdf: true });
        });
      }
    }
  }, [mapRef]);

  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showAllLocations) {
      setViewport({
        latitude: 38.7767,
        longitude: -96.797,
        zoom: 3.5,
      });
    }
  }, [showAllLocations]);

  useEffect(() => {
    if (selectedLocation) {
      setViewport({
        latitude: selectedLocation!.latitude,
        longitude: selectedLocation!.longitude,
        zoom: 7.5,
      });
      dispatch(setShowAllLocations(false));
    } else if (locations && locations.length > 0) {
      dispatch(selectLocation(locations[0]));
    }
  }, [selectedLocation, locations, dispatch]);

  const handleLocationClick = (location: MasterMapLocation) => {
    dispatch(selectLocation(locations?.find(l => l.id === location.id)));
  };

  //Gross hack to force 16:9 aspect ratio for map
  const cardRef = useRef<HTMLDivElement>(null);
  const width = (cardRef.current?.clientWidth ?? 0) * 0.5625;
  return (
    <Page
      docTitle='BroadcastView'
      title={
        <Typography variant='h3' color='textPrimary' noWrap>
          Broadcast View
        </Typography>
      }>
      <Grid container flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
        <Grid xs={8} item container>
          <Grid ref={cardRef} xs={12} item flex={1}>
            <Card fullContent>
              <ReactMapGL
                className='main-map'
                {...viewport}
                width='100%'
                height={width + 'px'}
                mapStyle={mapStyle}
                onViewportChange={onVPChange}
                interactiveLayerIds={getInteractiveLayerIds()}
                ref={mapRef}
                attributionControl={false}
                keyboard={false}
                style={{ cursor: 'cross-hair' }}>
                <AttributionControl style={{ bottom: 0, right: 0, fontSize: 10 }} compact={false} />
                <LocationMarker broadcast mapRef={mapRef} />
                {chosenLayers.includes('radar') && mapRef.current && mapRef.current.getMap() && (
                  <Radar
                    container={container}
                    location={selectedLocation}
                    hasLocation={selectedLocation !== undefined}
                    mapRef={mapRef.current?.getMap()}
                    viewport={viewport}
                    broadcastView={true}
                  />
                )}

                {mapRef.current && mapRef.current.getMap() && chosenLayers.includes('lightning') && (
                  <LightningQuickLayer viewport={viewport} mapRef={mapRef.current.getMap()} />
                )}

                {showAllLocations && locations && locations.length > 0 && (
                  <MasterMapLocationLayer locations={locations as MasterMapLocation[]} onClick={handleLocationClick} />
                )}

                <Grid
                  style={{ backgroundColor: 'rgba(0,0,0,0.7' }}
                  borderRadius={10}
                  padding={1}
                  paddingLeft={2}
                  paddingRight={2}
                  position={'absolute'}
                  bottom={10}
                  left={10}
                  container
                  display={'flex'}
                  flexShrink={1}
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'auto'}>
                  <Typography fontSize={16} color='textPrimary' noWrap>
                    Powered by Perry&nbsp;
                  </Typography>

                  {/* <PWLogo color={theme.palette.background.default} /> */}
                  <PWBroadcastLogo color={theme.palette.background.default} />
                  <Typography fontSize={16} color='textPrimary' noWrap>
                    &nbsp;Weather
                  </Typography>
                </Grid>
              </ReactMapGL>
            </Card>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={3}
          style={{ backgroundColor: theme.palette.background.paper, padding: 20, borderRadius: 10 }}
          flex={1}
          flexDirection={'column'}>
          <Typography textAlign={'start'} variant={'h6'}>
            Radar Controls
          </Typography>
          <Grid ref={container} xs={12} item flex={1}></Grid>
        </Grid>
      </Grid>
    </Page>
  );
}

function getMapStyle(type: baseLayerType) {
  switch (type) {
    case 'satellite':
      return `https://api.maptiler.com/maps/3cd87d39-7b76-49d8-ac14-7320e18cc301/style.json?key=${mapTilerKey}`;
    case 'dark':
      return `https://api.maptiler.com/maps/7a330e3e-5974-4112-9b7f-15f3a2416735/style.json?key=${mapTilerKey}`;
    case 'light':
      return `https://api.maptiler.com/maps/basic/style.json?key=${mapTilerKey}`;
  }
}

const PWBroadcastLogo = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width='12' height='20' viewBox='0 0 24 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.9907 11.5297C23.9909 9.48024 23.4194 7.46788 22.3351 5.70064C21.2509 3.9334 19.6933 2.47541 17.8234 1.47737C15.3958 0.126163 12.5324 -0.318832 9.78295 0.227849C9.46969 0.290325 9.13032 0.365296 8.85622 0.452762C6.313 1.11616 4.06825 2.56177 2.46788 4.56683C0.867514 6.57189 0.000342211 9.02512 0 11.5485V11.8234C0 12.2732 4.77982e-06 12.723 0.045689 13.1666C0.27411 15.5907 1.17474 17.8585 2.0819 20.1077C4.41024 25.7149 7.07078 31.1904 10.0505 36.5076C10.7032 37.6509 11.3166 38.7817 12.0084 40C12.1781 39.7189 12.3021 39.5252 12.4131 39.319C14.26 35.8516 16.1657 32.4092 17.9408 28.9043C20.0902 24.8032 21.8971 20.5457 23.3446 16.1717C23.8071 14.7571 24.0275 13.2803 23.9973 11.7984C23.9973 11.7047 23.9973 11.6172 23.9973 11.5235L23.9907 11.5297Z'
      fill='url(#paint0_linear)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.676 2.90161L5.3253 13.9598H10.9118L7.93583 22.5315L18.7108 10.8485H13.3918L15.676 2.90161Z'
      fill={props.color}
    />
    <defs>
      <linearGradient id='paint0_linear' x1='28.4219' y1='-3.5' x2='2.81967e-07' y2='40' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FFE37E' />
        <stop offset='1' stopColor='#22BACF' />
      </linearGradient>
    </defs>
  </svg>
);
