export enum NotificationEventTypes {
    Other = 1,
    Lightning = 2,
    WBGT = 3,
    HEAT = 4,
    CHILL = 5,
    PRECIP = 6,
    WIND = 7,
    GUST = 8,
    AQI = 9,
    SIREN = 10,
    NWS = 11
}
