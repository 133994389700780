import { ArrowDropDown } from '@mui/icons-material';
import {
  Autocomplete as MuiAutoComplete,
  AutocompleteChangeDetails,
  Chip,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Checkbox, Menu, PWMenuItem, SelectOption } from '@perry-weather/component-library';

export interface RecipientSelectOption extends SelectOption {
  type: 'site' | 'user';
  siteId: number;
}

interface SiteUser extends RecipientSelectOption {
  checked: boolean;
}

interface AutoCompleteProps {
  options: RecipientSelectOption[];
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  limitTags?: number;
  placeholder?: string;
  value?: RecipientSelectOption[];
  error?: boolean;
  helperText?: string;
  onChange?: (value: string[]) => void;
}

const massNotifyStyle = (theme: Theme) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: 'inherit',
    '& .MuiInputAdornment-root .MuiIconButton-root': {
      color: theme.palette.action.inactive,
    },
  },
  '& .MuiOutlinedInput-adornedEnd ': {
    padding: 0,
  },
  '& p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiSvgIcon-root': {
    padding: 0,
    color: theme.palette.text.primary,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // background: theme.palette.background.focus,
    borderWidth: '1px !important',
    boxShadow: '0px 0px 0px 2px #0F151E, 0px 0px 0px 4px rgba(96, 177, 215, 0.3)',
  },
  '& .MuiOutlinedInput-root.Mui-error': {
    '& .MuiInputAdornment-root': {
      color: theme.palette.error.light,
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px 32px 12px 14px',
    zIndex: 1,
  },
  '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: theme.palette.action.disabled,
  },
  '&:hover .Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: theme.palette.action.disabled,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 1,
    borderColor: theme.palette.border.input,
    borderRadius: 8,
  },
  '&:hover .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: theme.palette.action.focus,
  },
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.error.light,
  },
});

export function RecipientAutoComplete(props: AutoCompleteProps) {
  const { onChange } = props;
  const theme = useTheme();

  const [siteUsers, setSiteUsers] = useState<SiteUser[]>([]);
  const [selectedSite, setSelectedSite] = useState<number>(0);
  const [options, setOptions] = useState<RecipientSelectOption[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<RecipientSelectOption[]>([]);
  const [selectedSites, setSelectedSites] = useState<RecipientSelectOption[]>([]);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (props.value && props.options) {
      setSelectedUsers([]);

      if (props.value.length < 1) {
        setSelectedSites([]);
        setSiteUsers([]);
        setOptions(props.options);

        if (onChange) onChange([]);
      } else {
        let selectedRecipSites = props.value.map(x => x.siteId);
        let selSites = props.options
          .filter(x => selectedRecipSites.some(y => y === x.siteId))
          .filter(x => x.type === 'site');
        let selSiteUsers: SiteUser[] = props.value
          .map(x => ({ ...x, checked: true }))
          .concat(
            props.options
              .filter(opt => !props.value!.some(pv => pv.value === opt.value) && opt.type !== 'site')
              .map(x => ({ ...x, checked: false }))
          );

        setSelectedSites(selSites);

        setOptions(opts => opts.filter(opt => !selSites.some(s => s.siteId === opt.siteId)));

        setSiteUsers(selSiteUsers.sort((x, y) => (y.value as string).localeCompare(x.value as string)));

        if (onChange) onChange(selSiteUsers.filter(ssu => ssu.checked).map(ssu => ssu.value as string));
      }
    }
  }, [props.value, props.options]);

  useEffect(() => {
    if (props.options && props.options.length > 0) setOptions(props.options.sort((x, y) => x.siteId - y.siteId));
  }, [props.options]);

  const handleClose = () => {
    setSelectedSite(0);
    setAnchorEl(null);
  };

  const handleChipClick = (event: React.MouseEvent, selected: RecipientSelectOption) => {
    event.stopPropagation();
    if (selected.type === 'site') setSelectedSite(selected.siteId);

    setAnchorEl(event.currentTarget);
  };

  const handleSiteUserClick = (selSiteUser: SiteUser) => {
    let updatedUser = siteUsers.find(u => u.value === selSiteUser.value);
    updatedUser!.checked = !selSiteUser.checked;
    let selSiteUsers = [...siteUsers.filter(u => u.value !== selSiteUser.value), updatedUser!];
    setSiteUsers(selSiteUsers.sort((x, y) => (y.value as string).localeCompare(x.value as string)));

    if (onChange)
      onChange(
        selectedUsers
          .map(su => su.value as string)
          .concat(selSiteUsers.filter(ssu => ssu.checked).map(ssu => ssu.value as string))
      );
  };

  const handleChange = (
    event: React.SyntheticEvent,
    value: string | RecipientSelectOption | (string | RecipientSelectOption)[] | null,
    reason: string,
    details?: AutocompleteChangeDetails<RecipientSelectOption>
  ) => {
    let selectedValues = value as RecipientSelectOption[];
    let selUsers = selectedValues.filter(x => x.type === 'user');
    let selSiteUsers: SiteUser[] = [];

    switch (reason) {
      case 'removeOption':
        let rmvOption = selectedSites
          .concat(selectedUsers)
          .filter(x => !selectedValues.some(y => y.value === x.value))
          .pop();
        if (rmvOption!.type === 'site') {
          let sortedOptions: RecipientSelectOption[] = options
            .concat(
              props.options.filter(x => x.siteId === rmvOption!.siteId && !options.some(opt => opt.value === x.value))
            )
            .sort((x, y) => x.siteId - y.siteId);
          selSiteUsers = siteUsers.filter(u => u.siteId !== rmvOption!.siteId);
          setSiteUsers(selSiteUsers);
          setOptions(sortedOptions);
          setSelectedSites(selectedSites.filter(ss => ss.value !== rmvOption!.value));
        } else setSelectedUsers(selectedUsers.filter(su => su.value !== rmvOption!.value));

        if (onChange)
          onChange(selUsers.map(sv => sv.value as string).concat(selSiteUsers.map(ssu => ssu.value as string)));
        break;
      case 'clear':
        let sortedOptions: RecipientSelectOption[] = props.options.sort((x, y) => x.siteId - y.siteId);
        setSiteUsers([]);
        setSelectedSites([]);
        setSelectedUsers([]);
        setOptions(sortedOptions);
        if (onChange) onChange([]);
        break;
      case 'selectOption':
      default:
        let selOption = selectedValues
          .filter(x => !selectedSites.concat(selectedUsers).some(y => y.value === x.value))
          .pop();
        if (selOption && selOption.type === 'site') {
          selSiteUsers = siteUsers.concat(
            props.options
              .filter(x => x.type === 'user' && x.siteId === selOption!.siteId)
              .map(x => ({ ...x, checked: true }))
          );

          setOptions(opts => opts.filter(x => x.siteId !== selOption!.siteId || x.type !== 'user'));

          setSiteUsers(selSiteUsers.sort((x, y) => (y.value as string).localeCompare(x.value as string)));

          selUsers = selUsers.filter(x => x.siteId !== selOption!.siteId);
          setSelectedSites([...selectedSites, selOption]);
        }
        setSelectedUsers(selUsers);

        if (onChange)
          onChange(selUsers.map(su => su.value as string).concat(selSiteUsers.map(ssu => ssu.value as string)));
        break;
    }
  };

  return (
    <>
      <MuiAutoComplete
        {...props}
        options={options}
        value={selectedSites.concat(selectedUsers)}
        onChange={handleChange}
        sx={massNotifyStyle(theme)}
        filterSelectedOptions
        getOptionLabel={option => option.text}
        renderInput={params => (
          <TextField {...params} error={props.error} helperText={props.helperText} placeholder={props.placeholder} />
        )}
        renderTags={(value, getTagProps) =>
          value
            .sort((x, y) => (x.type === 'site' ? -1 : 1))
            .map((option, index) =>
              option.type === 'site' ? (
                <div key={index} onClick={e => handleChipClick(e, option)}>
                  <Chip
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.background.paper,
                      '& .MuiChip-deleteIcon': { color: theme.palette.background.paper },
                    }}
                    {...getTagProps({ index })}
                    label={
                      <div>
                        {`${option.text}${
                          siteUsers.filter(x => x.checked && x.siteId === option.siteId).length <
                          props.options.filter(x => x.type === 'user' && x.siteId === option.siteId).length
                            ? ' ' +
                              siteUsers.filter(x => x.checked && x.siteId === option.siteId).length +
                              '/' +
                              props.options.filter(x => x.type === 'user' && x.siteId === option.siteId).length
                            : ''
                        }`}
                        <ArrowDropDown style={{ verticalAlign: 'middle', color: theme.palette.background.paper }} />
                      </div>
                    }
                  />
                </div>
              ) : (
                <Chip
                  sx={{
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.background.paper,
                    '& .MuiChip-deleteIcon': { color: theme.palette.background.paper },
                  }}
                  {...getTagProps({ index })}
                  label={option.text}
                />
              )
            )
        }
        renderOption={(params, option) => (
          <PWMenuItem
            {...params}
            onMouseOver={undefined}
            key={option.value}
            value={option.value}
            onClick={e => {
              if (params.onClick) params.onClick(e);
            }}
            sx={{
              '&:hover': {
                backgroundColor: theme.palette.action.hoverSecondary,
              },
            }}>
            <div
              style={{
                textIndent: option.type === 'site' ? '0px' : '20px',
                fontStyle: option.italic ? 'italic' : 'normal',
                fontWeight: option.bold ? 'bold' : 'normal',
              }}>
              {option.text || option.value}
            </div>
          </PWMenuItem>
        )}
      />
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {siteUsers.filter(x => x.siteId === selectedSite).length > 0 ? (
          siteUsers
            .filter(x => x.siteId === selectedSite)
            .map(x => (
              <PWMenuItem value={x.value} key={x.value} onClick={e => handleSiteUserClick(x)}>
                <Checkbox checked={x.checked} />
                {x.text}
              </PWMenuItem>
            ))
        ) : (
          <PWMenuItem disabled>
            <Typography variant='body2'>No Users</Typography>
          </PWMenuItem>
        )}
      </Menu>
    </>
  );
}
