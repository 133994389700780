/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Theme, Typography, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { MemoizedUsersTable } from '../Components/Team/UsersTable';
import { useNotiUsersQuery, User, useRolesQuery, useSitesQuery, useUsersQuery } from '../Services/API';
import { TeamFormModal } from '../Components/Team/TeamFormModal';
import { Menu, Button, SearchField, useDocumentTitle } from '@perry-weather/component-library';
import { useAuth } from 'oidc-react';
import { Add, ArrowDropDownOutlined, FilterAltOutlined } from '@mui/icons-material';
import { UserTableFilterAccordion } from '../Components/Team/UserTableFilterAccordion';
import { GroupsModal } from '../Components/Team/GroupsModal';
import { Redirect } from 'wouter';

interface FilterSelection {
  inactiveUsers: boolean;
  activeUsers: boolean;

  userGroups: string[];
  userRoles: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBox: {
      [theme.breakpoints.down('lg')]: {
        marginBottom: 16,
      },
    },
  })
);

export function Users() {
  const classes = useStyles();
  const { userData: user } = useAuth();
  const theme = useTheme();

  const { data: users, isLoading: usersIsLoading, isFetching: usersIsFetching } = useUsersQuery();
  const { data: roles, isLoading: rolesIsLoading, isFetching: rolesIsFetching } = useRolesQuery();
  const { data: sites, isLoading: sitesIsLoading, isFetching: sitesIsFetching } = useSitesQuery();
  const { data: notiUsers, isLoading: notiUsersIsLoading, isFetching: notiUsersIsFetching } = useNotiUsersQuery();

  const [searchString, setSearchString] = useState('');
  const [usersFilter, setUsersFilter] = useState<FilterSelection | undefined>(undefined);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();

  const currentUser = users?.find(u => u.id === user?.profile.sub);

  const [filterAnchor, setFilterAnchor] = useState<Element | null>(null);

  const openFilter = Boolean(filterAnchor);

  // const [editedUser, setEditedUser] = useState<string>()

  const [openUserModal, setUserModalOpen] = React.useState(false);
  const [openGroupsModal, setGroupsModalOpen] = React.useState(false);

  const chipContainer = useRef(null);

  useMemo(() => {
    if (sites && roles && !usersFilter)
      setUsersFilter({
        inactiveUsers: true,
        activeUsers: true,
        userGroups:
          currentUser && currentUser.roleHierarchy === 3 ? [currentUser.siteName] : [...sites.map(x => x.name)],
        userRoles: [...roles.map(x => x.name), 'Notification Only'],
      });
    if (currentUser && currentUser.roleHierarchy === 3) setIsFiltered(true);
  }, [sites, roles]);

  useDocumentTitle('Users - Perry Weather');

  const handleFilterSelection = (selection: FilterSelection) => {
    let tempFilterSelection: FilterSelection = selection;

    if (selection.userRoles.length < 1) {
      if (roles) tempFilterSelection.userRoles = [...roles.map(x => x.name), 'Notification Only'];
    }

    if (selection.userGroups.length < 1) {
      if (sites) tempFilterSelection.userGroups = sites.map(x => x.name);
    }

    if (!selection.activeUsers && !selection.inactiveUsers) {
      tempFilterSelection.activeUsers = true;
      tempFilterSelection.inactiveUsers = true;
    }

    setUsersFilter(tempFilterSelection);
  };

  const handleEditUserModal = (userId: string) => {
    if (users && notiUsers) {
      let allUsers = users.concat(notiUsers);
      setSelectedUser(allUsers.find(u => u.id === userId));
    }
    setUserModalOpen(true);
  };

  const renderTable = () => {
    // console.debug(usersFilter)

    if (users && notiUsers) {
      let combinedResult: User[] = [];
      combinedResult.push(...users);
      combinedResult.push(...notiUsers);
      combinedResult.sort((x, y) => (x.firstName.toUpperCase() < y.firstName.toUpperCase() ? -1 : 1));

      let shownUsers: User[] = users;
      if (usersFilter)
        shownUsers = combinedResult.filter(
          x =>
            ((usersFilter!.inactiveUsers && !x.isActive) || (usersFilter!.activeUsers && x.isActive)) &&
            usersFilter!.userRoles.includes(x.roleName) &&
            usersFilter!.userGroups.includes(x.siteName)
        );

      const searchRegex = new RegExp(searchString.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
      const filteredRows = shownUsers.filter((row: any) => {
        return Object.keys(row).some((field: any) => {
          return searchRegex.test(row[field] !== null ? row[field].toString() : true);
        });
      });

      if (usersIsFetching || sitesIsFetching || rolesIsFetching || notiUsersIsFetching) return <></>;

      return (
        currentUser &&
        filteredRows && (
          <MemoizedUsersTable currentUser={currentUser} rows={filteredRows} onEditRowClick={handleEditUserModal} />
        )
      );
    }
  };

  if (
    !(
      user?.profile?.Role === 'Admin' ||
      user?.profile?.Role === 'Super Admin' ||
      user?.profile?.Role === 'Assistant Admin'
    )
  ) {
    return <Redirect to='/Unauthorized' />;
  }

  if (usersIsLoading || rolesIsLoading || notiUsersIsLoading || sitesIsLoading)
    return (
      <Typography variant='body1' color='textPrimary'>
        Loading...
      </Typography>
    );

  return (
    <Grid container style={{ padding: '0 8px' }}>
      <Grid item container style={{ marginBottom: 16 }}>
        <Grid item xs={12} sm={4}>
          <Typography variant='h3' style={{ textAlign: 'left' }}>
            {' '}
            Users{' '}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={1}
          marginBottom={2}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            [theme.breakpoints.down('sm')]: { justifyContent: 'flex-start' },
          }}>
          <Grid order={{ xs: 3, lg: 1 }} item xs={12} md={10} lg={6} xl={7} className={classes.searchBox}>
            <SearchField
              value={searchString}
              placeholder='Search...'
              handleSearch={s => {
                setSearchString(s);
                setSearchString(s);
              }}
            />
          </Grid>
          <Grid order={{ xs: 4, lg: 2 }} item>
            <Button type='clear' onClick={e => setFilterAnchor(e.currentTarget)}>
              <FilterAltOutlined />
              <Typography>Filter</Typography>
              <ArrowDropDownOutlined />
            </Button>
            <Menu open={openFilter} anchorEl={filterAnchor} onClose={e => setFilterAnchor(null)}>
              <UserTableFilterAccordion
                currentUser={currentUser}
                chipContainer={chipContainer}
                onFilterSelectionChange={(f, filtered) => {
                  handleFilterSelection(f);
                  setIsFiltered(filtered);
                }}
              />
            </Menu>
          </Grid>

          <Grid order={{ xs: 1, lg: 3 }} item marginBottom='8px'>
            {(user?.profile?.Role === 'Admin' ||
              user?.profile?.Role === 'Super Admin' ||
              user?.profile?.Role === 'Assistant Admin') && (
              <Button type='clearBlue' onClick={e => setGroupsModalOpen(true)}>
                <Typography>Manage Groups</Typography>
              </Button>
            )}
          </Grid>
          <Grid order={{ xs: 1, lg: 4 }} item>
            {(user?.profile?.Role === 'Admin' ||
              user?.profile?.Role === 'Super Admin' ||
              user?.profile?.Role === 'Assistant Admin') && (
              <Button
                onClick={e => {
                  setUserModalOpen(true);
                }}>
                <Add />
                <Typography>Add User</Typography>
              </Button>
            )}
          </Grid>
          {currentUser && (
            <TeamFormModal
              modalState={{
                currentUser: currentUser,
                selectedUser: selectedUser,
                open: openUserModal,
                handleClose: () => {
                  setSelectedUser(undefined);
                  setUserModalOpen(false);
                },
                handleOpen: () => setUserModalOpen(true),
              }}
            />
          )}
          <GroupsModal
            modalState={{
              open: openGroupsModal,
              handleClose: () => {
                setGroupsModalOpen(false);
              },
              handleOpen: () => setGroupsModalOpen(true),
            }}
          />
        </Grid>
        <Grid container ref={chipContainer}>
          {/* This information is from UserTableFilterAccordions Portal Component */}
        </Grid>
      </Grid>
      {renderTable()}
    </Grid>
  );
}
export default Users;
