import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Role, useRolesQuery } from '../../Services/API';
import { PWMenuItem, Select, SelectOption, Tooltip } from '@perry-weather/component-library';

interface RoleSelectProps {
  value: string;
  userRole: number;
  onSelect: (role: Role) => void;
  isError?: boolean;
}

export function RoleSelect(props: RoleSelectProps) {
  const { value, userRole, onSelect, isError } = props;

  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);

  const { data: roles } = useRolesQuery();

  const handleSelect = (value: string | number, text: string) => {
    if (roles) {
      let selectedRole = roles.find(r => r.id === value);
      if (value === -1) selectedRole = { id: '-1', name: text, displayName: text, hierarchy: 5 };
      if (selectedRole) onSelect(selectedRole);
    }
  };

  const renderRoleItem = (option: SelectOption) => {
    let tooltip = '';

    const roleName = option.value === -1 ? 'Notification Only' : roles?.find(x => x.id === option.value)?.name;

    const optionHierarchy = option.value === -1 ? 5 : roles?.find(x => x.id === option.value)?.hierarchy || 0;

    switch (roleName) {
      case 'Admin':
        tooltip = 'Full permissions';
        break;
      case 'Assistant Admin':
        tooltip = 'Full permissions, limited to specific user group(s) only';
        break;
      case 'Customer':
        tooltip =
          'Basic permissions. Cannot edit policies, users, and hardware settings. Cannot use Talk to a Meteorologist';
        break;
      case 'Notification Only':
        tooltip = 'Receives notifications only. Cannot sign in to Perry Weather on web or mobile.';
        break;
    }

    return (
      <PWMenuItem key={option.value} value={option.value} disabled={userRole >= optionHierarchy}>
        <Tooltip title={<Typography>{tooltip}</Typography>} placement='left'>
          <Typography width='100%'>{option.text || option.value}</Typography>
        </Tooltip>
      </PWMenuItem>
    );
  };

  useEffect(() => {
    if (roles)
      setRoleOptions([
        ...roles.filter(r => r.id > '1').map(role => ({ value: role.id, text: role.displayName || role.name })),
        { value: -1, text: 'Notification Only' },
      ]);
  }, [roles]);

  return (
    <Select
      isError={isError}
      value={parseInt(value) === 0 ? '' : value}
      options={roleOptions}
      onChange={handleSelect}
      renderMenuItem={renderRoleItem}
    />
  );
}
