import { useState } from 'react';
import { InputRow, styledLinkClass } from '../../Base';
import { Divider, Grid, Typography, useTheme } from '@mui/material';
import {
  IntegrationConfig,
  useAddOrganizationLocationSiteMutation,
  useAddSinglewireMappingMutation,
  useAddWebhookOrgLocSiteMutation,
  useCustomerOrgLocationsQuery,
  useOrganizationLocationSitesQuery,
  useSinglewireScenariosQuery,
  useSinglewireSitesQuery,
  useSitesQuery,
} from '../../../Services/API';
import { LoadingButton, ModalState, Button, Modal, Select } from '@perry-weather/component-library';
import { ArrowRightAlt } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { openToast } from '../../../features/toast/toastSlice';

type ConfigureModalProps = {
  modalState: ModalState;
  integrationConfig: IntegrationConfig;
};

export const ConfigureModal = ({ modalState, integrationConfig }: ConfigureModalProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [formState, setFormState] = useState({
    orgLocationId: '',
    orgLocationName: '',
    policyGroupId: '',
    siteName: '',
    singlewireScenarioId: '',
    singlewireScenarioName: '',
    singlewireSiteId: '',
    singlewireSiteName: '',
  });

  const { data: orgLocations } = useCustomerOrgLocationsQuery(integrationConfig.customerId);
  const { data: sites } = useSitesQuery();
  const { data: singlewireSites } = useSinglewireSitesQuery(integrationConfig.customerId);
  const { data: singlewireScenarios } = useSinglewireScenariosQuery(integrationConfig.customerId);
  const { data: orgLocSites } = useOrganizationLocationSitesQuery(formState.orgLocationId, {
    skip: !formState.orgLocationId,
  });
  const [addOrganizationLocationSite] = useAddOrganizationLocationSiteMutation();
  const [addWebhookOrgLocSite] = useAddWebhookOrgLocSiteMutation();
  const [addSinglewireMapping] = useAddSinglewireMappingMutation();

  const linkClass = styledLinkClass(theme);

  const isComplete = Object.values(formState).every(value => value !== '');

  const onSaveClick = async () => {
    try {
      setIsSaving(true);
      const existingOrgLocSite = orgLocSites?.find(
        ({ siteId, organizationLocationId }) =>
          siteId === formState.policyGroupId && organizationLocationId === formState.orgLocationId
      );

      // Setup a new Organization Location Site if it doesn't exist
      let orgLocSiteId: string | undefined;
      if (!existingOrgLocSite) {
        const orgLocSiteResponse = await addOrganizationLocationSite({
          organizationLocationId: formState.orgLocationId,
          siteId: formState.policyGroupId,
        });
        if (orgLocSiteResponse.hasOwnProperty('error')) {
          console.error('Error adding new Org Loc Site:', orgLocSiteResponse);
          dispatch(
            openToast({
              variant: 'error',
              header: 'Error saving scenario',
              message: 'Please try again later',
            })
          );
          return;
        }
        orgLocSiteId = orgLocSiteResponse.data.id;
      } else {
        orgLocSiteId = existingOrgLocSite.id;
      }

      // Register the OrgLocSite as a listener on the webhook
      const webhookOrgLocSiteResponse = await addWebhookOrgLocSite({
        orgLocSiteId: orgLocSiteId!,
        webhookId: integrationConfig.webhookId,
      });

      if (webhookOrgLocSiteResponse.hasOwnProperty('error')) {
        console.error('Error adding new Org Loc Site:', webhookOrgLocSiteResponse);
        dispatch(
          openToast({
            variant: 'error',
            header: 'Error saving scenario',
            message: 'Please try again later',
          })
        );
        return;
      }

      // Finally save the scenario mapping
      const mappingResponse = await addSinglewireMapping({
        integrationConfigId: integrationConfig.id!,
        organizationLocationId: formState.orgLocationId,
        policyGroupId: Number(formState.policyGroupId),
        singlewireSiteId: formState.singlewireSiteId,
        singlewireScenarioId: formState.singlewireScenarioId,
      });
      if (mappingResponse.hasOwnProperty('error')) {
        console.error('Error adding new mapping:', mappingResponse);
        dispatch(
          openToast({
            variant: 'error',
            header: 'Error saving scenario',
            message: 'Please try again later',
          })
        );
        return;
      }

      dispatch(
        openToast({
          variant: 'success',
          header: 'Singlewire notification scenario saved',
        })
      );
      modalState.handleClose();
    } catch (e) {
      console.error('Exception saving a scenario mapping:', e);
      dispatch(
        openToast({
          variant: 'error',
          header: 'Error saving scenario',
          message: 'Please try again later',
        })
      );
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal modalState={modalState}>
      <Grid container justifyContent='center'>
        <Grid item xs={12} marginBottom='8px'>
          <Typography variant='h5'>Add Scenario Trigger</Typography>
        </Grid>
        <Grid item xs={12} direction='row' display='flex'>
          <Typography variant='body1' color='textSecondary'>
            Click{' '}
            <a
              href='https://www.singlewire.com/informacast'
              className={linkClass.link}
              rel='noreferrer'
              target='_blank'>
              here
            </a>
            &nbsp;to learn more about the Singlewire InformaCast Integration.
          </Typography>
        </Grid>
        <Divider sx={{ width: '100%', margin: '8px 0' }} />
        <Grid container item xs={12}>
          <Typography variant='body1' color='textSecondary' marginBottom={4}>
            First, select the Policy Group and Location to observe:
          </Typography>
          <InputRow label='Policy Group'>
            <Select
              value={formState.policyGroupId}
              options={sites?.map(({ name, id }) => ({ text: name, value: id })) ?? []}
              onChange={(policyGroupId, siteName) => setFormState({ ...formState, policyGroupId, siteName })}
              placeholder='Select a Policy Group'
            />
          </InputRow>
          <InputRow label='At Location'>
            <Select
              value={formState.orgLocationId}
              options={orgLocations?.map(({ name, id }) => ({ text: name, value: id })) ?? []}
              onChange={(orgLocationId, orgLocationName) =>
                setFormState({ ...formState, orgLocationId, orgLocationName })
              }
              placeholder='Select a Location'
            />
          </InputRow>
        </Grid>
        <Divider sx={{ width: '100%', margin: '8px 0' }} />
        <Grid container item xs={12}>
          <Typography variant='body1' color='textSecondary' marginBottom={4}>
            Next, select which Singlewire Scenario will trigger, along with the corresponding Singlewire Site
          </Typography>
          <InputRow label='Scenario'>
            <Select
              value={formState.singlewireScenarioId}
              options={singlewireScenarios?.map(({ name, id }) => ({ text: name, value: id })) ?? []}
              onChange={(singlewireScenarioId, singlewireScenarioName) =>
                setFormState({ ...formState, singlewireScenarioId, singlewireScenarioName })
              }
              placeholder='Select a Scenario'
            />
          </InputRow>
          <InputRow label='At Site'>
            <Select
              value={formState.singlewireSiteId}
              options={singlewireSites?.map(({ name, id }) => ({ text: name, value: id })) ?? []}
              onChange={(singlewireSiteId, singlewireSiteName) =>
                setFormState({ ...formState, singlewireSiteId, singlewireSiteName })
              }
              placeholder='Select a Site'
            />
          </InputRow>
        </Grid>
        <Divider sx={{ width: '100%', margin: '8px 0' }} />
        {isComplete && (
          <Grid container item xs={12} marginBottom={2}>
            <Grid item xs={5}>
              <Typography variant='body1' color='textSecondary'>
                When Policy Group
              </Typography>
              <Typography variant='body1' fontWeight='bold'>
                {formState.siteName}
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                is triggered at
              </Typography>
              <Typography variant='body1' fontWeight='bold'>
                {formState.orgLocationName}
              </Typography>
            </Grid>
            <Grid item xs={2} container justifyContent='center' alignItems='center'>
              <ArrowRightAlt sx={{ width: '40px', height: '40px' }} />
            </Grid>
            <Grid item xs={5} container alignItems='end' direction='column'>
              <Typography variant='body1' color='textSecondary'>
                Trigger Scenario
              </Typography>
              <Typography variant='body1' textAlign='end' fontWeight='bold'>
                {formState.singlewireScenarioName}
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                at site
              </Typography>
              <Typography variant='body1' textAlign='end' fontWeight='bold'>
                {formState.singlewireSiteName}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container display='flex' justifyContent='flex-end' spacing={1}>
          <Grid item>
            <Button type='clear' onClick={() => modalState.handleClose()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              onClick={() => onSaveClick()}
              disabled={!isComplete}
              loadingText='Saving'
              isLoading={isSaving}
              finishedLoading={() => {}}>
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
